import { 
  AlertTypeAction, 
  IAlertAction, 
  IAlertState, 
  AlertType } from './../types';

const initialState: IAlertState = {
  alertType: AlertType.INFO,
  message: '',
  isAlertShowed: false,
};

export const alertReducer = (
  state: IAlertState = initialState, 
  action: IAlertAction): IAlertState => {
    
  switch (action.type) {
    case AlertTypeAction.ALERT_SUCCESS: 
      return {
        ...state,
        alertType: action.alertType,
        message: action.message,
        isAlertShowed: action.isAlertShowed,
      };
    case AlertTypeAction.ALERT_ERROR:
      return {
        ...state,
        alertType: action.alertType,
        message: action.message,
        isAlertShowed: action.isAlertShowed,
      };
    case AlertTypeAction.ALERT_INFO:
      return {
        ...state,
        alertType: action.alertType,
        message: action.message,
        isAlertShowed: action.isAlertShowed,
      };
    case AlertTypeAction.ALERT_WARNING:
      return {
        ...state,
        alertType: action.alertType,
        message: action.message,
        isAlertShowed: action.isAlertShowed,
      };
    case AlertTypeAction.ALERT_CLEAR:
      return {
        ...state,
        alertType: action.alertType,
        message: action.message,
        isAlertShowed: action.isAlertShowed, 
      };
    default: return state;
  }
};
