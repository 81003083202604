import { AnyAction } from 'redux';

export enum ApiDocTypeAction {
  API_DOC_RECEIVE_INIT = 'API_DOC_RECEIVE_INIT',
  API_DOC_RECEIVE_SUCCESS = 'API_DOC_RECEIVE_SUCCESS',
  API_DOC_RECEIVE_FAILED = 'API_DOC_RECEIVE_FAILED',
}

export interface IApiDocState {
  doc: object | undefined;
  isLoading: boolean;
  isError: boolean;
}

export interface IApiDocReceiveInitAction extends AnyAction {
  type: string;
}

export interface IApiDocReceiveFailedAction extends AnyAction {
    type: string;
}

export interface IApiDocReceiveSuccessAction extends AnyAction {
    type: string;
    doc: object;
}

export type IApiDocAction =
  IApiDocReceiveInitAction |
  IApiDocReceiveFailedAction |
  IApiDocReceiveSuccessAction;
