import { message } from 'antd';

import { 
  UserManagerTypeAction, 
  IUserManagerAction, 
  IUserManagerState } from './../types';
import { User } from './../models';

const initialState: IUserManagerState = {
  usersList: [],
  selectedUser: undefined,
  isUserAdding: false,
  isUserListLoading: false,
  isCreateModalOpen: false,
  isUserUpdating: false,
  isResetPasswordModalOpen: false
};

export const userManagerReducer = (
  state: IUserManagerState = initialState,
  action: IUserManagerAction) => {
  
  switch (action.type) {
    
    case UserManagerTypeAction.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        isUserListLoading: true,
      };

    case UserManagerTypeAction.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.usersList,
        isUserListLoading: false,
      };

    case UserManagerTypeAction.GET_USERS_LIST_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        usersList: [],
        selectedUser: undefined,
        isUserListLoading: false,
      };

    case UserManagerTypeAction.SELECT_USER:
      return {
        ...state,
        selectedUser: action.selectedUser,
      };

    case UserManagerTypeAction.OPEN_CREATING_USER_WINDOW:
      return {
        ...state,
        isCreateModalOpen: true,
      };

    case UserManagerTypeAction.CLOSE_CREATING_USER_WINDOW:
      return {
        ...state,
        isCreateModalOpen: false,
      };

    case UserManagerTypeAction.CREATE_USER_REQUEST:
      return {
        ...state,
        isUserAdding: true,
      };

    case UserManagerTypeAction.CREATE_USER_SUCCESS:
      state.usersList.push(action.createdUser);
      message.success(action.messageText, 4);
      return {
        ...state,
        isUserAdding: false,
        selectedUser: action.createdUser,
      };

    case UserManagerTypeAction.CREATE_USER_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isUserAdding: false,
      };
      
    case UserManagerTypeAction.REMOVE_USER_REQUEST:
      return {
        ...state,
        isUserListLoading: true,
      };

    case UserManagerTypeAction.REMOVE_USER_SUCCESS:
      const indexRemovedUser = state.usersList
        .findIndex((user: User) =>  user.id === action.removedUserId);
      state.usersList.splice(indexRemovedUser, 1);
      message.success(action.messageText, 4);
      const selectedUser = state.usersList[0] ? state.usersList[0] : undefined;
      return {
        ...state,
        selectedUser: selectedUser,
        isUserListLoading: false,
      };

    case UserManagerTypeAction.REMOVE_USER_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isUserListLoading: false,
      };

    case UserManagerTypeAction.RESET_USER_PASSWORD_REQUEST:
      return {
        ...state,
        isUserUpdating: true,
      };

    case UserManagerTypeAction.RESET_USER_PASSWORD_SUCCESS:
      message.success(action.messageText, 4);
      return {
        ...state,
        isUserUpdating: false,
      };

    case UserManagerTypeAction.RESET_USER_PASSWORD_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isUserUpdating: false,
      };

    case UserManagerTypeAction.OPEN_RESET_PASSWORD_WINDOW:
      return {
        ...state,
        isResetPasswordModalOpen: true,
      };

    case UserManagerTypeAction.CLOSE_RESET_PASSWORD_WINDOW:
      return {
        ...state,
        isResetPasswordModalOpen: false,
      };

    default: return state;
  }
};