import config from '../config';
import { API_PATH } from '../config/api';
import CONSTANTS from '../constants/HttpConstants';
import { authService } from './../services';

const { API_HOST } = config;
const { GET, POST, DELETE, JSON_CONTENT_TYPE } = CONSTANTS;

export default class Http {

  get(uri: string) {
    return fetch(`${API_HOST}${API_PATH}${uri}`, {
      method: GET,
      mode: 'cors',
      headers: {
        'Content-Type': JSON_CONTENT_TYPE,
        'Authorization': `token ${this.token()}`
      }
    })
      .then(this.catchUnauthorized);
  }

  post(uri: string, data: Object) {
    return fetch(`${API_HOST}${API_PATH}${uri}`, {
      method: POST,
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        Accept: JSON_CONTENT_TYPE,
        'Content-Type': JSON_CONTENT_TYPE,
        'Authorization': `token ${this.token()}`
      }
    })
      .then(this.catchUnauthorized);
  }

  delete(uri: string) {
    return fetch(`${API_HOST}${API_PATH}${uri}`, {
      method: DELETE,
      mode: 'cors',
      headers: {
        'Authorization': `token ${this.token()}`
      }
    })
      .then(this.catchUnauthorized);
  }

  private token(): string {
    const currentUser = authService.getCurrentUser();
    return currentUser ? currentUser?.userToken : '';    
  }

  private catchUnauthorized(response: Response): Response {
    if (response.status === 403) {
      authService.logout();
      location.reload();
    }
    return response;
  }
}
