import { AnyAction } from 'redux';
import { ApiEndpoint } from 'src/models';

export enum ApiEndpointsTypeAction {
  API_ENDPOINTS_RECEIVE_INIT = 'API_ENDPOINTS_RECEIVE_INIT',
  API_ENDPOINTS_RECEIVE_SUCCESS = 'API_ENDPOINTS_RECEIVE_SUCCESS',
  API_ENDPOINTS_RECEIVE_FAILED = 'API_ENDPOINTS_RECEIVE_FAILED',
  API_ENDPOINT_SWITCH = 'API_ENDPOINT_SWITCH',
  API_ENDPOINTS_SET_ALL = 'API_ENDPOINTS_SET_ALL',
}

type HTTPMethod = 'GET' | 'POST' | 'UPDATE' | 'DELETE';
export interface IApiEndpointsState {
  endpoints: ApiEndpoint[];
  isLoading: boolean;
}

export interface IOperation {
  method: HTTPMethod;
  operationId: string;
}

export interface IEndpointsRequestList {
  id: string;
  operations: IOperation[];
}

export interface IApiEndpointsSetAllAction extends AnyAction {
  type: string;
  value: boolean;
}

export interface IApiEndpointsReceiveInitAction extends AnyAction {
  type: string;
}

export interface IApiEndpointsReceiveSuccessAction extends AnyAction {
  type: string;
  endpoints: ApiEndpoint[];
}

export interface IApiEndpointsReceiveFailedAction extends AnyAction {
  type: string;
  error: string;
}

export interface IApiEndpointSwitchAction extends AnyAction {
  type: string;
  index: number;
}

export type IApiEndpointsAction =
  IApiEndpointsReceiveInitAction |
  IApiEndpointsReceiveSuccessAction |
  IApiEndpointsReceiveFailedAction |
  IApiEndpointSwitchAction |
  IApiEndpointsSetAllAction;
