import { message } from 'antd';

import {
  PricingApiContextTypeAction,
  IPricingApiContextListAction,
  IPricingApiContextState } from '../types';
import { Context } from '../models';

const initState: IPricingApiContextState = {
  contextsList: [],
  selectedContext: undefined,
  isContextsLoading: false,
  isContextAdding: false,
  isContextEditing: false,  
};

export const pricingApiContextReducer = (
  state: IPricingApiContextState = initState,
  action: IPricingApiContextListAction): IPricingApiContextState => {
    switch (action.type) {

      case PricingApiContextTypeAction.GET_PRICING_API_CONTEXTS_REQUEST:
        return {
          ...state,
          isContextsLoading: true,
        };

      case PricingApiContextTypeAction.GET_PRICING_API_CONTEXTS_SUCCESS:
        return {
          ...state,
          contextsList: action.contextsList,
          isContextsLoading: false,
        };

      case PricingApiContextTypeAction.GET_PRICING_API_CONTEXTS_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          contextsList: [],
          selectedContext: undefined,
          isContextsLoading: false,
        };

      case PricingApiContextTypeAction.SELECT_PRICING_API_CONTEXT:
        return {
          ...state,
          selectedContext: action.selectedContext,
        };

      case PricingApiContextTypeAction.ADD_PRICING_API_CONTEXT_REQUEST:
        return {
          ...state,
          isContextAdding: true,
        };
      
      case PricingApiContextTypeAction.ADD_PRICING_API_CONTEXT_SUCCESS:
        state.contextsList.push(action.addedContext);
        message.success(action.messageText, 4);
        return {
          ...state,
          isContextAdding: false,
        };

      case PricingApiContextTypeAction.ADD_PRICING_API_CONTEXT_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isContextAdding: false,
        };

      case PricingApiContextTypeAction.EDIT_PRICING_API_CONTEXT_REQUEST:
        return {
          ...state,
          isContextEditing: true,
        };

      case PricingApiContextTypeAction.EDIT_PRICING_API_CONTEXT_SUCCESS:
        const editedIndex = state.contextsList
          .findIndex((context: Context) => 
          context.id === action.editedContext.id);
        state.contextsList.splice(editedIndex, 1);
        state.contextsList.push(action.editedContext);
        message.success(action.messageText, 4);
        return {
          ...state,
          isContextEditing: false,
        };

      case PricingApiContextTypeAction.EDIT_PRICING_API_CONTEXT_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isContextEditing: false,
        };

      case PricingApiContextTypeAction.REMOVE_PRICING_API_CONTEXT_REQUEST:
        return{
          ...state,
          isContextsLoading: true,
        };

      case PricingApiContextTypeAction.REMOVE_PRICING_API_CONTEXT_SUCCESS:
        const removedIndex = state.contextsList
          .findIndex((context: Context) => 
          context.id === action.removedPricingApiContextId);
        state.contextsList.splice(removedIndex, 1);
        message.success(action.messageText, 4);
        return {
          ...state,
          isContextsLoading: false,
        };
        
      case PricingApiContextTypeAction.REMOVE_PRICING_API_CONTEXT_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isContextsLoading: false,
        };

      default: return state;
    }
  };