import { message } from 'antd';

import {
  IPartnerEndpointGroupsAction,
} from '../types';
import { IPartnerEndpointGroupsState, PartnerEndpointGroupsTypes } from 'src/types/PartnerEndpointGroupsTypes';
import PartnerEndpointGroup from 'src/models/PartnerEndpointGroup';

const initialState: IPartnerEndpointGroupsState = {
  partnerEndpointGroups: [],
  isPartnerEndpointGroupsLoading: false,
  isCreateModalOpen: false,
  isUpdateModalOpen: false,
  selectedPartnerEndpointGroup: undefined
};

export const partnerEndpointGroupsReducer = (
  state: IPartnerEndpointGroupsState = initialState,
  action: IPartnerEndpointGroupsAction): IPartnerEndpointGroupsState => {

  switch (action.type) {
    case PartnerEndpointGroupsTypes.PARTNER_ENDPOINT_GROUPS_REQUEST:
      return {
        ...state,
        isPartnerEndpointGroupsLoading: true,
      };
    case PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUPS_SUCCESS:
      return {
        ...state,
        partnerEndpointGroups: action.partnerEndpointGroups,
        isPartnerEndpointGroupsLoading: false,
      };
    case PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUPS_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        partnerEndpointGroups: [],
        isPartnerEndpointGroupsLoading: false,
      };

    case PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUPS_SUCCESS:
      state.partnerEndpointGroups.push(action.payload);
      return {
        ...state,
        isPartnerEndpointGroupsLoading: false,
        isCreateModalOpen: false
      };
    case PartnerEndpointGroupsTypes.DELETE_PARTNER_ENDPOINT_GROUPS_SUCCESS:
      const partnerEndpointGroups = [...state.partnerEndpointGroups];
      let deleteTargetIndex = partnerEndpointGroups.findIndex(
        (item: PartnerEndpointGroup) => item.id === action.payload);

      if (deleteTargetIndex > -1) {
        partnerEndpointGroups.splice(deleteTargetIndex, 1);
      }
      return {
        ...state,
        isPartnerEndpointGroupsLoading: false,
        partnerEndpointGroups
      };
    case PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUP_ERROR:
    case PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUPS_ERROR:
    case PartnerEndpointGroupsTypes.DELETE_PARTNER_ENDPOINT_GROUPS_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isPartnerEndpointGroupsLoading: false
      };
    case PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN:
      return {
        ...state,
        isCreateModalOpen: true
      };
    case PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE:
      return {
        ...state,
        isCreateModalOpen: false
      };
    case PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUPS_SUCCESS:
      const updatePartnerEndpointsGroups = [...state.partnerEndpointGroups];
      const payload = action.payload;
      const updateTargetIndex = updatePartnerEndpointsGroups
        .findIndex(item => item.id === payload.id);

      if (updateTargetIndex > -1) {
        updatePartnerEndpointsGroups.splice(updateTargetIndex, 1, payload);
      }

      return {
        ...state,
        partnerEndpointGroups: updatePartnerEndpointsGroups,
        isPartnerEndpointGroupsLoading: false,
        isUpdateModalOpen: false
      };
    case PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUPS_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isPartnerEndpointGroupsLoading: false
      };
    case PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN:
      return {
        ...state,
        isUpdateModalOpen: true
      };
    case PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE:
      return {
        ...state,
        isUpdateModalOpen: false
      };
    case PartnerEndpointGroupsTypes.SELECT_PARTNER_ENDPOINT_GROUP:
      return {
        ...state,
        selectedPartnerEndpointGroup: action.payload
      };
    default: return state;
  }
};
