import * as React from 'react';
import { Layout } from 'antd';

const { Footer: AntFooter } = Layout;

export const Footer = () => {
  return (
    <AntFooter className="footer">
      © {(new Date().getFullYear())} AXS, Inc. All Rights Reserved.
    </AntFooter>
  );
};
