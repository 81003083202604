import { combineReducers, Reducer } from 'redux';

import { alertReducer } from './AlertReducer';
import { authReducer } from './AuthReducer';
import { apiDocReducer } from './ApiDocReducer';
import { apiEndpointsReducer } from './ApiEndpointsReducer';
import { partnersListReducer } from './PartnersListReducer';
import { contextPropertyReducer } from './ContextPropertyReducer';
import { partnerMemberReducer } from './PartnerMemberReducer';
import { partnerPermissionsReducer } from './PartnerPermissionsReducer';
import { userManagerReducer } from './UserManagerReducer';
import { pricingApiContextReducer } from './PricingApiContextReducer';
import { partnerEndpointGroupsReducer } from './PartnerEndpointGroupsReducer';

import {
  IAlertState,
  IAuthState,
  IApiDocState,
  IPartnersListState,
  IContextPropertyState,
  IPartnerMemberState,
  IPartnerPermissionsState,
  IApiEndpointsState,
  IUserManagerState,
  IPricingApiContextState,
  IPartnerEndpointGroupsState
} from './../types';

export interface IRootState {
  alertState: IAlertState;
  authState: IAuthState;
  apiDocState: IApiDocState;
  apiEndpointsState: IApiEndpointsState;
  partnersListState: IPartnersListState;
  partnerEndpointGroupsState: IPartnerEndpointGroupsState;
  contextPropertyState: IContextPropertyState;
  partnerMemberState: IPartnerMemberState;
  partnerPermissionsState: IPartnerPermissionsState;
  userManagerState: IUserManagerState;
  pricingApiContextState: IPricingApiContextState;
}

const rootReducer: Reducer<IRootState> = combineReducers<IRootState>({
  alertState: alertReducer,
  authState: authReducer,
  apiDocState: apiDocReducer,
  apiEndpointsState: apiEndpointsReducer,
  partnersListState: partnersListReducer,
  partnerEndpointGroupsState: partnerEndpointGroupsReducer,
  contextPropertyState: contextPropertyReducer,
  partnerMemberState: partnerMemberReducer,
  partnerPermissionsState: partnerPermissionsReducer,
  userManagerState: userManagerReducer,
  pricingApiContextState: pricingApiContextReducer,
});

export default rootReducer; 
