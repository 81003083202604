import { 
    ApiEndpointsTypeAction, 
    IApiEndpointsState,
    IApiEndpointsAction, 
    IApiEndpointsReceiveSuccessAction,
    IApiEndpointSwitchAction,
    IApiDocReceiveSuccessAction,
    IApiEndpointsSetAllAction
} from './../types';
import { ApiEndpoint } from 'src/models';
import { message } from 'antd';
  
const initialState: IApiEndpointsState = {
    endpoints: [],
    isLoading: false,
  };

const updateItem = (items: any[], index: number, updater: any) => ([
    ...items.slice(0, index),
    Object.assign({...items[index]}, updater(items[index])),
    ...items.slice(index + 1)
]);

const updateItems = 
  (items: any[], updater: any) => (items.map((item) => Object.assign({...item}, updater(item))));

const updatesMap = new Map();
updatesMap.set(ApiEndpointsTypeAction.API_ENDPOINTS_RECEIVE_INIT, () => ({
  isLoading: true,
}));
updatesMap.set(ApiEndpointsTypeAction.API_ENDPOINTS_RECEIVE_SUCCESS, 
               (action: IApiEndpointsReceiveSuccessAction) => ({
  endpoints: action.endpoints,
  isLoading: false,
}));
updatesMap.set(ApiEndpointsTypeAction.API_ENDPOINTS_RECEIVE_FAILED, (action: any) => ({
  isLoading: false,
}));
updatesMap.set(ApiEndpointsTypeAction.API_ENDPOINT_SWITCH,
               (action: IApiEndpointSwitchAction, state: IApiEndpointsState) => ({
    endpoints: updateItem(state.endpoints, action.index, 
                          (item: ApiEndpoint) => ({isAllowed: !item.isAllowed}) )
}));

updatesMap.set(ApiEndpointsTypeAction.API_ENDPOINTS_SET_ALL,
               (action: IApiEndpointsSetAllAction, state: IApiEndpointsState) => ({
endpoints: updateItems(state.endpoints, (item: ApiEndpoint) => ({isAllowed: action.value}) )
}));

const getUpdate = (action: IApiEndpointsAction, state: IApiEndpointsState): object => {
  if (updatesMap.has(action.type)) {
    return updatesMap.get(action.type)(action, state);
  }
  return initialState;
};

export const apiEndpointsReducer = 
(state: IApiEndpointsState = initialState, action: IApiEndpointsAction): IApiEndpointsState => {
    if (action.error) {
        message.error(action.error, 4);
    }
    const update = getUpdate(action, state);
    const updatedState = Object.assign({...state}, update);
    return updatedState;
  };
  