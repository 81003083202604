import {
  AC2_SERVICE_DOC,
  COMPOSITE_PURCHASE_DOC,
  DOC_PATH,
  ENDPOINTS_PATH,
  TRANSFER_API_DOC,
  RECALL_API_DOC,
  PRICING_API_DOC,
  EXTERNAL_PURCHASE_DOC,
  RESALE_DOC,
  FULFILLMENT_DOC,
  PUSH_INVENTORY_API_DOC,
} from '../config/api';
import Http from '../utils/http';
import ApiEndpoint from '../models/ApiEndpoint';
import { IEndpointsRequestList } from 'src/types';

const http = new Http();

const mapEndpoints = (data: any): ApiEndpoint[] => {
  const result = [];
  for (const endpoint of data.endpoints) {
    result.push(new ApiEndpoint(endpoint.id, endpoint.isAllowed, endpoint.operations));
  }
  return result;
};

class ApiDocService {
  public getEndpoints(partnerEndpointGroupId: string): Promise<ApiEndpoint[]> {
    return http
      .get(`${DOC_PATH}${ENDPOINTS_PATH}/${partnerEndpointGroupId}`)
      .then(this.handleEndpointsResponse);
  }

  public setEndpoints(
    endpoints: IEndpointsRequestList[],
    partnerEndpointGroupId: string
  ): Promise<ApiEndpoint[]> {
    return http
      .post(
        `${DOC_PATH}${ENDPOINTS_PATH}/${partnerEndpointGroupId}`,
        this.buildSetEndpointsPayload(endpoints)
      )
      .then(this.handleEndpointsResponse);
  }

  public getDoc(): Promise<any> {
    return http.get(`${DOC_PATH}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getCompositePurchaseDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${COMPOSITE_PURCHASE_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getExternalPurchaseDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${EXTERNAL_PURCHASE_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getTransferApiDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${TRANSFER_API_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getResaleDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${RESALE_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getFulfillmentDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${FULFILLMENT_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getPushInventoryApiDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${PUSH_INVENTORY_API_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getRecallApiDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${RECALL_API_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getPricingApiDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${PRICING_API_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getAc2ServiceDoc(): Promise<any> {
    return http.get(`${DOC_PATH}${AC2_SERVICE_DOC}`)
      .then(async (response: Response) => {
        if (response.ok) {
          return await response.json();
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  private handleEndpointsResponse(response: Response) {
    if (response.ok) {
      return response.json()
        .then(data => mapEndpoints(data));
    } else {
      throw Error(`${response.status} ${response.statusText}`);
    }
  }

  private buildSetEndpointsPayload(endpoints: IEndpointsRequestList[]): any {
    const items = endpoints.map(({ id, operations }) => { return { id, operations }; });
    return { endpoints: items };
  }
}

export const apiDocService = new ApiDocService();
