import * as React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import { RoutePath } from '../../../constants';

const { Content } = Layout;

export const NotFound = () => (
  <Layout className="not_found">
    <Content>
      <p className="title">404</p>
      <p className="msg">Page not found</p>
      <p className="go_home">You can <Link to={RoutePath.LOGIN}>return to the home page.</Link></p>
    </Content>
  </Layout>
);