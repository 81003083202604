import { PARTNER_PATH } from '../config/api';
import Http from '../utils/http';
import Partner from '../models/Partner';

const http = new Http();

class PartnerService {
  public getPartners(): Promise<Partner[]> {
    return http.get(PARTNER_PATH)
      .then((response: Response) => {
        if (response.ok) {
          return response.json()
            .then(data => this.mapPartners(data));
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public getPartner(id: number): Promise<Partner> {
    return http.get(`${PARTNER_PATH}/${id}`)
      .then((response: Response) => {
        if (response.ok) {
          return response.json()
            .then(data => this.mapBodyToPartner(data));
        } else {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  public addPartner(partner: Partner): Promise<number> {
    return http.post(PARTNER_PATH, this.mapPartnerToBody(partner))
      .then((response: Response) => {
        if (response.ok) {
          return response.json()
            .then(data => data[0]);
        } else {
          return response.text()
            .then((errText: string) => {
              throw Error(`${response.status} ${response.statusText} - ${errText}`);
            });
        }
      });
  }

  public savePartner(partner: Partner): Promise<void> {
    return http.post(`${PARTNER_PATH}/${partner.id}`, this.mapPartnerToBody(partner))
      .then((response: Response) => {
        if (response.ok) {
          return response.json()
            .then(data => data);
        } else {
          return response.text()
            .then((errText: string) => {
              throw Error(`${response.status} ${response.statusText} - ${errText}`);
            });
        }
      });
  }

  public deletePartner(id: number): Promise<void> {
    return http.delete(`${PARTNER_PATH}/${id}`)
      .then((response: Response) => {
        if (!response.ok) {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  private mapBodyToPartner(body: any): Partner {
    return {
      id: body.id,
      partnerName: body.name,
      partnerToken: body.token,
      expirationDate: new Date(body.expiresAt),
      loadPriceComponents: body.loadPriceComponents,
      agentSystemName: body.agentSystemName,
      allowDynamicPrice: body.allowDynamicPrice,
      enableCompositePurchase: body.enableCompositePurchase,
      enableSkeletonAccount: body.enableSkeletonAccount,
      enableFlashRecall: body.enableFlashRecall,
      enableFlashTransfer: body.enableFlashTransfer,
      enablePlaceReservation: body.enablePlaceReservation,
      enableUpdatePriceChart: body.enableUpdatePriceChart,
      enableCancelTickets: body.enableCancelTickets,
      enableExternalPurchase: body.enableExternalPurchase,
      partnerEndpointGroupId: body.partnerEndpointGroupId,
      enableResaleApiCalls: body.enableResaleApiCalls,
      enableAccountAdministration: body.enableAccountAdministration,
      enableFulfillmentApiAccess: body.enableFulfillmentApiAccess,
      requiredFulfillmentMarketplaceInfo: body.requiredFulfillmentMarketplaceInfo,
      maxTicketTransfersAmount: body.maxTicketTransfersAmount,
      enablePushInventoryApiAccess: body.enablePushInventoryApiAccess,
      ac2ServiceAccess: body.ac2ServiceAccess,
      ac2ServiceToken: body.ac2ServiceToken,
      ac2ServiceClientId: body.ac2ServiceClientId,
    };
  }

  private mapPartnerToBody(partner: Partner): any {
    return {
      name: partner.partnerName,
      expires_at: partner.expirationDate.toISOString(),
      load_price_components: partner.loadPriceComponents,
      agent_system_name: partner.agentSystemName,
      allow_dynamic_price: partner.allowDynamicPrice,
      enable_composite_purchase: partner.enableCompositePurchase,
      enable_skeleton_account: partner.enableSkeletonAccount,
      enable_flash_recall: partner.enableFlashRecall,
      enable_flash_transfer: partner.enableFlashTransfer,
      enable_place_reservation: partner.enablePlaceReservation,
      enable_update_price_chart: partner.enableUpdatePriceChart,
      enable_cancel_tickets: partner.enableCancelTickets,
      enable_external_purchase: partner.enableExternalPurchase,
      partnerEndpointGroupId: partner.partnerEndpointGroupId,
      enable_resale_api_calls: partner.enableResaleApiCalls,
      enable_account_administration: partner.enableAccountAdministration,
      enable_fulfillment_api_access: partner.enableFulfillmentApiAccess,
      required_fulfillment_marketplace_info: partner.requiredFulfillmentMarketplaceInfo,
      max_ticket_transfers_amount: partner.maxTicketTransfersAmount,
      enable_push_inventory_api_access: partner.enablePushInventoryApiAccess,
      ac2_service_access: partner.ac2ServiceAccess,
      ac2_service_token: partner.ac2ServiceToken,
      ac2_service_client_id: partner.ac2ServiceClientId,
    };
  }

  private mapPartners(data: any): Partner[] {
    return data.map((item: any) => this.mapBodyToPartner(item));
  }
}

export const partnerService = new PartnerService();
