import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

interface IAppContainerProps {
  routes: any;
  store: any;
  history: any;  
}

export class Index extends React.Component<IAppContainerProps> {
  public render() {
    const { store, history, routes } = this.props;
    return(
      <Provider store={store}>
        <Router history={history}>
          {routes}       
        </Router>
      </Provider>      
    );
  }
}