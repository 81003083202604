import { 
  AuthTypeAction, 
  IAuthAction,
  IAuthState } from './../types';

const initialState: IAuthState = {
  currentUser: null,
  isAuthenticating: false,
};

export const authReducer = (
  state: IAuthState = initialState, 
  action: IAuthAction): IAuthState => {
    
  switch (action.type) {
    case AuthTypeAction.LOGIN_BEGIN: 
      return {
        ...state,
        currentUser: action.currentUser,  
        isAuthenticating: action.isAuthenticating,      
      };
      
    case AuthTypeAction.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.currentUser,
        isAuthenticating: action.isAuthenticating,
      };

    case AuthTypeAction.LOGIN_FAILURE:
      return {
        ...state,
        currentUser: action.currentUser,
        isAuthenticating: action.isAuthenticating,
      };

    case AuthTypeAction.LOGOUT:
      return {
        ...state,
        currentUser: action.currentUser,
        isAuthenticating: action.isAuthenticating,
      };
      
    default: return initialState;
  }
};
