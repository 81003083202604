import { message } from 'antd';
import { 
  PartnerPermissionsTypeAction,
  IPartnerPermissionsListAction,
  IPartnerPermissionsState } from '../types';
import { Context, Offer, Outlet } from '../models';

const initialState: IPartnerPermissionsState = {
  partnerContextList: [],
  partnerOfferList: [],
  partnerOutletList: [],
  selectedPartnerContext: undefined,
  selectedPartnerOffer: undefined,
  selectedPartnerOutlet: undefined,
  isPartnerContextsLoading: false,
  isPartnerOffersLoading: false,
  isPartnerOutletsLoading: false,
  isPartnerContextAdding: false,
  isPartnerOfferAdding: false,
  isPartnerOutletAdding: false,
};

export const partnerPermissionsReducer = (
  state: IPartnerPermissionsState = initialState,
  action: IPartnerPermissionsListAction): IPartnerPermissionsState => {
  
    switch (action.type) {

      case PartnerPermissionsTypeAction.SELECT_PARTNER_CONTEXT:
        return {
          ...state,
          selectedPartnerContext: action.selectedPartnerContext,
        };

      case PartnerPermissionsTypeAction.SELECT_PARTNER_OFFER:
        return {
          ...state,
          selectedPartnerOffer: action.selectedPartnerOffer,
        };

      case PartnerPermissionsTypeAction.SELECT_PARTNER_OUTLET:
        return {
          ...state,
          selectedPartnerOutlet: action.selectedPartnerOutlet,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_CONTEXT_REQUEST:
        return {
          ...state,
          isPartnerContextsLoading: true,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_OFFER_REQUEST:
        return {
          ...state,
          isPartnerOffersLoading: true,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_OUTLET_REQUEST:
        return {
          ...state,
          isPartnerOutletsLoading: true,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_CONTEXT_SUCCESS:
        return {
          ...state,
          partnerContextList: action.partnerContextList,
          isPartnerContextsLoading: false,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_OFFER_SUCCESS:
        return {
          ...state,
          partnerOfferList: action.partnerOfferList,
          isPartnerOffersLoading: false,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_OUTLET_SUCCESS:
        return {
          ...state,
          partnerOutletList: action.partnerOutletList,
          isPartnerOutletsLoading: false,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_CONTEXT_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          partnerContextList: [],
          selectedPartnerContext: undefined,
          isPartnerContextsLoading: false,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_OFFER_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          partnerOfferList: [],
          selectedPartnerOffer: undefined,
          isPartnerOffersLoading: false,
        };

      case PartnerPermissionsTypeAction.GET_PARTNER_OUTLET_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          partnerOutletList: [],
          selectedPartnerOutlet: undefined,
          isPartnerOutletsLoading: false,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_CONTEXT_REQUEST:
        return {
          ...state,
          isPartnerContextAdding: true,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_OFFER_REQUEST:
        return {
          ...state,
          isPartnerOfferAdding: true,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_OUTLET_REQUEST:
        return {
          ...state,
          isPartnerOutletAdding: true,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_CONTEXT_SUCCESS:
        state.partnerContextList.push(action.addedPartnerContext);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerContextAdding: false,
          selectedPartnerContext: action.addedPartnerContext,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_OFFER_SUCCESS:
        state.partnerOfferList.push(action.addedPartnerOffer);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerOfferAdding: false,
          selectedPartnerOffer: action.addedPartnerContext,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_OUTLET_SUCCESS:
        state.partnerOutletList.push(action.addedPartnerOutlet);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerOutletAdding: false,
          selectedPartnerOutlet: action.addedPartnerOutlet,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_CONTEXT_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerContextAdding: false,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_OFFER_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerOfferAdding: false,
        };

      case PartnerPermissionsTypeAction.ADD_PARTNER_OUTLET_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerOutletAdding: false,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_CONTEXT_REQUEST:
        return {
          ...state,
          isPartnerContextsLoading: true,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_OFFER_REQUEST:
        return {
          ...state,
          isPartnerOffersLoading: true,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_OUTLET_REQUEST:
        return {
          ...state,
          isPartnerOutletsLoading: true,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_CONTEXT_SUCCESS:
        const indexRemovedContext = state.partnerContextList
          .findIndex((context: Context) => context.id === action.removedPartnerContextId);
        state.partnerContextList.splice(indexRemovedContext, 1);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerContextsLoading: false,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_OFFER_SUCCESS:
        const indexRemovedOffer = state.partnerOfferList
          .findIndex((offer: Offer) => offer.id === action.removedPartnerOfferId);
        state.partnerOfferList.splice(indexRemovedOffer, 1);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerOffersLoading: false,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_OUTLET_SUCCESS:
        const indexRemovedOutlet = state.partnerOutletList
          .findIndex((outlet: Outlet) => outlet.id === action.removedPartnerOutletId);
        state.partnerOutletList.splice(indexRemovedOutlet, 1);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerOutletsLoading: false,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_CONTEXT_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerContextsLoading: false,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_OFFER_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerOffersLoading: false,
        };

      case PartnerPermissionsTypeAction.REMOVE_PARTNER_OUTLET_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerOutletsLoading: false,
        };

      default: return state;
    }
  };