import * as React from 'react';
import { Input as AntInput } from 'antd';

interface IInputProps {
  id?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  size?: 'small' | 'large' | 'middle' | 'default';
  suffix?: string | React.ReactNode;
  addonAfter?: string | React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const Input = (props: IInputProps) => {
  return (
    <AntInput
      id={props.id}
      className={`input input__${props.size || 'default'}`}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      onPressEnter={props.onPressEnter}
      disabled={props.disabled}
      placeholder={props.placeholder}
      type={props.type}
      suffix={props.suffix}
      addonAfter={props.addonAfter}
    />
  );
};
