import { AnyAction } from 'redux';
import { Context } from '../models';

export enum PricingApiContextTypeAction {
  GET_PRICING_API_CONTEXTS_REQUEST = 'GET_PRICING_API_CONTEXTS_REQUEST',
  GET_PRICING_API_CONTEXTS_SUCCESS = 'GET_PRICING_API_CONTEXTS_SUCCESS',
  GET_PRICING_API_CONTEXTS_ERROR = 'GET_PRICING_API_CONTEXTS_ERROR',
  ADD_PRICING_API_CONTEXT_REQUEST = 'ADD_PRICING_API_CONTEXT_REQUEST',
  ADD_PRICING_API_CONTEXT_SUCCESS = 'ADD_PRICING_API_CONTEXT_SUCCESS',
  ADD_PRICING_API_CONTEXT_ERROR = 'ADD_PRICING_API_CONTEXT_ERROR',
  EDIT_PRICING_API_CONTEXT_REQUEST = 'EDIT_PRICING_API_CONTEXT_REQUEST',
  EDIT_PRICING_API_CONTEXT_SUCCESS = 'EDIT_PRICING_API_CONTEXT_SUCCESS',
  EDIT_PRICING_API_CONTEXT_ERROR = 'EDIT_PRICING_API_CONTEXT_ERROR',
  REMOVE_PRICING_API_CONTEXT_REQUEST = 'REMOVE_PRICING_API_CONTEXT_REQUEST',
  REMOVE_PRICING_API_CONTEXT_SUCCESS = 'REMOVE_PRICING_API_CONTEXT_SUCCESS',
  REMOVE_PRICING_API_CONTEXT_ERROR = 'REMOVE_PRICING_API_CONTEXT_ERROR',
  SELECT_PRICING_API_CONTEXT = 'SELECT_PRICING_API_CONTEXT',
}

export interface IPricingApiContextState {
  contextsList: Context[];
  selectedContext: Context | undefined;
  isContextsLoading: boolean;
  isContextAdding: boolean;
  isContextEditing: boolean;
}

export interface ISelectPricingApiContext {
  type: PricingApiContextTypeAction.SELECT_PRICING_API_CONTEXT;
  selectedContext: Context;
}

export interface IGetPricingApiContextsRequestAction extends AnyAction {
  type: PricingApiContextTypeAction.GET_PRICING_API_CONTEXTS_REQUEST;
}

export interface IGetPricingApiContextsSuccessAction extends AnyAction {
  type: PricingApiContextTypeAction.GET_PRICING_API_CONTEXTS_SUCCESS;
  contextsList: Context[];
}

export interface IGetPricingApiContextsErrorAction extends AnyAction {
  type: PricingApiContextTypeAction.GET_PRICING_API_CONTEXTS_ERROR;
  messageText: string;
}

export interface IAddPricingApiContextRequestAction extends AnyAction {
  type: PricingApiContextTypeAction.ADD_PRICING_API_CONTEXT_REQUEST;
}

export interface IAddPricingApiContextSuccessAction extends AnyAction {
  type: PricingApiContextTypeAction.ADD_PRICING_API_CONTEXT_SUCCESS;
  addedContext: Context;
  messageText: string;
}

export interface IAddPricingApiContextErrorAction extends AnyAction {
  type: PricingApiContextTypeAction.ADD_PRICING_API_CONTEXT_ERROR;
  messageText: string;
}

export interface IEditPricingApiContextRequestAction extends AnyAction {
  type: PricingApiContextTypeAction.EDIT_PRICING_API_CONTEXT_REQUEST;
}

export interface IEditPricingApiContextSuccessAction extends AnyAction {
  type: PricingApiContextTypeAction.EDIT_PRICING_API_CONTEXT_SUCCESS;
  editedContext: Context;
  messageText: string;
}

export interface IEditPricingApiContextErrorAction extends AnyAction {
  type: PricingApiContextTypeAction.EDIT_PRICING_API_CONTEXT_ERROR;
  messageText: string;
}

export interface IRemovePricingApiContextRequestAction extends AnyAction {
  type: PricingApiContextTypeAction.REMOVE_PRICING_API_CONTEXT_REQUEST;
}

export interface IRemovePricingApiContextSuccessAction extends AnyAction {
  type: PricingApiContextTypeAction.REMOVE_PRICING_API_CONTEXT_SUCCESS;
  removedPricingApiContextId: number;
  messageText: string;
}

export interface IRemovePricingApiContextErrorAction extends AnyAction {
  type: PricingApiContextTypeAction.REMOVE_PRICING_API_CONTEXT_ERROR;
  messageText: string;
}

export type IPricingApiContextListAction = 
  ISelectPricingApiContext |
  IGetPricingApiContextsRequestAction |
  IGetPricingApiContextsSuccessAction |
  IGetPricingApiContextsErrorAction |
  IAddPricingApiContextRequestAction | 
  IAddPricingApiContextSuccessAction | 
  IAddPricingApiContextErrorAction | 
  IEditPricingApiContextRequestAction | 
  IEditPricingApiContextSuccessAction | 
  IEditPricingApiContextErrorAction |
  IRemovePricingApiContextRequestAction |
  IRemovePricingApiContextSuccessAction |
  IRemovePricingApiContextErrorAction;