import * as React from 'react';
import { PageLayout } from 'src/layouts/PageLayout';
import { Layout } from 'antd';
import { EndpointsListContainer } from 'src/containers/EndpointsList';

export class DocEditPage extends React.Component {
  render() {
    return (
      <PageLayout>
        <Layout className="doc-edit">
          <EndpointsListContainer />
        </Layout>
      </PageLayout>
    );
  }
}
