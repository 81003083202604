import * as React from 'react';
import { DocLayout } from 'src/layouts/DocLayout';
import { Layout } from 'antd';
import { PricingApiDoc } from 'src/containers/PricingApiDoc';

export class PricingApiDocPage extends React.Component {
  render() {
    return (
      <DocLayout>
        <Layout className="doc-root">
            <PricingApiDoc/>
        </Layout>
      </DocLayout>
    );
  }
}
