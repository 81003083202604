import * as React from 'react';
import { Alert as AntAlert } from 'antd';

interface IAlertProps {
  message: string | React.ReactNode;
  type: 'success' | 'info' | 'warning' | 'error';
  closable?: boolean;
  banner?: boolean;
  afterClose?: () => void;
}

export const Alert = (props: IAlertProps) => {
  return (
    <AntAlert
      type={props.type}
      message={props.message}
      closable={props.closable}
      afterClose={props.afterClose}
      banner={props.banner}
      className={`alert alert__${props.type}`}
    />
  );
};
