import { message } from 'antd';

import {
  ContextPropertyTypeAction,
  IContextPropertyListAction,
  IContextPropertyState } from './../types';
import { ContextProperty } from './../models';

const initState: IContextPropertyState = {
  contextPropertiesList: [],
  selectedContextProperty: undefined,
  isContextPropertiesLoading: false,
  isContextPropertyAdding: false,
  isContextPropertyEditing: false,  
};

export const contextPropertyReducer = (
  state: IContextPropertyState = initState,
  action: IContextPropertyListAction): IContextPropertyState => {
    switch (action.type) {

      case ContextPropertyTypeAction.GET_CONTEXT_PROPERTIES_REQUEST:
        return {
          ...state,
          isContextPropertiesLoading: true,
        };

      case ContextPropertyTypeAction.GET_CONTEXT_PROPERTIES_SUCCESS:
        return {
          ...state,
          contextPropertiesList: action.contextPropertiesList,
          isContextPropertiesLoading: false,
        };

      case ContextPropertyTypeAction.GET_CONTEXT_PROPERTIES_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          contextPropertiesList: [],
          selectedContextProperty: undefined,
          isContextPropertiesLoading: false,
        };

      case ContextPropertyTypeAction.SELECT_CONTEXT_PROPERTY:
        return {
          ...state,
          selectedContextProperty: action.selectedContextProperty,
        };

      case ContextPropertyTypeAction.ADD_CONTEXT_PROPERTY_REQUEST:
        return {
          ...state,
          isContextPropertyAdding: true,
        };
      
      case ContextPropertyTypeAction.ADD_CONTEXT_PROPERTY_SUCCESS:
        state.contextPropertiesList.push(action.addedContextProperty);
        message.success(action.messageText, 4);
        return {
          ...state,
          isContextPropertyAdding: false,
        };

      case ContextPropertyTypeAction.ADD_CONTEXT_PROPERTY_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isContextPropertyAdding: false,
        };

      case ContextPropertyTypeAction.EDIT_CONTEXT_PROPERTY_REQUEST:
        return {
          ...state,
          isContextPropertyEditing: true,
        };

      case ContextPropertyTypeAction.EDIT_CONTEXT_PROPERTY_SUCCESS:
        const indexEditedContextProperty = state.contextPropertiesList
          .findIndex((contextProperty: ContextProperty) => 
            contextProperty.id === action.editedContextProperty.id);
        state.contextPropertiesList.splice(indexEditedContextProperty, 1);
        state.contextPropertiesList.push(action.editedContextProperty);
        message.success(action.messageText, 4);
        return {
          ...state,
          isContextPropertyEditing: false,
        };

      case ContextPropertyTypeAction.EDIT_CONTEXT_PROPERTY_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isContextPropertyEditing: false,
        };

      case ContextPropertyTypeAction.REMOVE_CONTEXT_PROPERTY_REQUEST:
        return{
          ...state,
          isContextPropertiesLoading: true,
        };

      case ContextPropertyTypeAction.REMOVE_CONTEXT_PROPERTY_SUCCESS:
        const indexRemovedContextProperty = state.contextPropertiesList
          .findIndex((contextProperty: ContextProperty) => 
            contextProperty.id === action.removedContextPropertyId);
        state.contextPropertiesList.splice(indexRemovedContextProperty, 1);
        message.success(action.messageText, 4);
        return {
          ...state,
          isContextPropertiesLoading: false,
        };
        
      case ContextPropertyTypeAction.REMOVE_CONTEXT_PROPERTY_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isContextPropertiesLoading: false,
        };

      default: return state;
    }
  };