import * as React from 'react';
import { Layout } from 'antd';

import { PartnersListContainer } from '../../containers/PartnersList';
import { PartnerDetailsContainer } from '../../containers/PartnerDetails';

import { PageLayout } from 'src/layouts/PageLayout';

const { Content } = Layout;

export class PartnerPage extends React.Component {
  
  render() {
    return (
      <PageLayout>
        <Layout className="partners_page">
          <PartnersListContainer />
          <Content className="content">
            <PartnerDetailsContainer />
          </Content>   
        </Layout>
      </PageLayout>
    );
  }
}
