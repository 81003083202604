import { AnyAction } from 'redux';

export enum AuthTypeAction {
  LOGIN_BEGIN = 'LOGIN_BEGIN',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
}

export interface ILoginUser {
  login: string;
  password: string;
}

export interface ICurrentUser {
  userName: string;
  userToken: string;
  userRoles: string[];
  userPermissions: string[];
  partnerEndpointGroupId?: number;
}

export interface IAuthState {
  currentUser: ICurrentUser | null;
  isAuthenticating: boolean;
}

export interface IAuthAction extends AnyAction {
  type: AuthTypeAction;
  isAuthenticating: boolean;
  currentUser: ICurrentUser | null;
}
