export enum RoutePath {
  LOGIN = '/',
  PARTNERS = '/partners',
  PARTNER_ENDPOINT_GROUPS = '/partner-endpoint-groups',
  DOC = '/doc',
  DOC_COMPOSITE_PURCHASE = '/doc/composite-purchase',
  DOC_EXTERNAL_PURCHASE = '/doc/external-purchase',
  DOC_RESALE = '/doc/resale',
  DOC_FULFILLMENT = '/doc/fulfillment',
  DOC_TRANSFER_API = '/doc/transfer-api',
  DOC_PUSH_INVENTORY_API = '/doc/push-inventory-api',
  DOC_RECALL_API = '/doc/recall-api',
  DOC_UPDATE_PRICE_CHART_API = '/doc/pricing-api',
  DOC_AC2_SERVICE = '/doc/ac2-service',
  DOC_EDIT = '/doc/edit',
  USERS = '/users',
  UNKNOWN_PATH = '*'
}
