import PartnerEndpointGroup from 'src/models/PartnerEndpointGroup';
import { PARTNER_GROUP_PATH, } from '../config/api';
import Http from '../utils/http';

const http = new Http();

class PartnerEndpointGroupsService {

  public getPartnerEndpointGroups(): Promise<PartnerEndpointGroup[]> {
    return http.get(`${PARTNER_GROUP_PATH}`)
      .then((response: Response) => {
        if (!response.ok) {
          return response.text().then((errText: string) => {
            throw Error(`${response.status} ${response.statusText} - ${errText}`);
          });
        }
        return response.json();
      });
  }

  public getPartnerEndpointGroup(id: number): Promise<PartnerEndpointGroup> {
    return http.get(`${PARTNER_GROUP_PATH}/${id}`)
      .then((response: Response) => {
        if (!response.ok) {
          return response.text().then((errText: string) => {
            throw Error(`${response.status} ${response.statusText} - ${errText}`);
          });
        }
        return response.json();
      });
  }

  public createPartnerEndpointGroup(data: PartnerEndpointGroup): Promise<number> {
    return http.post(`${PARTNER_GROUP_PATH}`, data)
      .then((response: Response) => {
        if (!response.ok) {
          return response.text().then((errText: string) => {
            throw Error(`${response.status} ${response.statusText} - ${errText}`);
          });
        }
        return response.json().then((record) => record?.[0]);
      });
  }

  public updatePartnerEndpointGroup(data: PartnerEndpointGroup, id: number)
    : Promise<PartnerEndpointGroup> {
    return http.post(`${PARTNER_GROUP_PATH}/${id}`, data)
      .then((response: Response) => {
        if (!response.ok) {
          return response.text().then((errText: string) => {
            throw Error(`${response.status} ${response.statusText} - ${errText}`);
          });
        }
        return response.json();
      });
  }

  public deletePartnerEndpointGroup(id: number): Promise<any> {
    return http.delete(`${PARTNER_GROUP_PATH}/${id}`)
      .then((response: Response) => {
        if (!response.ok) {
          return response.text().then((errText: string) => {
            throw Error(`${response.status} ${response.statusText} - ${errText}`);
          });
        }
        return response.json();
      });
  }
}

export const partnerEndpointGroupsService = new PartnerEndpointGroupsService();
