import { message } from 'antd';

import { 
  PartnerListTypeAction, 
  IPartnersListAction, 
  IPartnersListState } from './../types';
import { Partner } from './../models';

const initialState: IPartnersListState = {
  partnersList: [],
  partnerUsers: [],
  selectedPartner: undefined,
  isPartnersListLoading: false,
  isPartnerAdding: false, 
  isPartnerEditing: false
};

export const partnersListReducer = (
  state: IPartnersListState = initialState, 
  action: IPartnersListAction): IPartnersListState => {
    
  switch (action.type) {

    case PartnerListTypeAction.GET_PARTNERS_LIST_REQUEST: 
      return {
        ...state,
        isPartnersListLoading: true,
      };

    case PartnerListTypeAction.GET_PARTNERS_LIST_SUCCESS:
      return {
        ...state,
        partnersList: action.partnersList,
        isPartnersListLoading: false,
      };

    case PartnerListTypeAction.GET_PARTNERS_LIST_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        partnersList: [],
        selectedPartner: undefined,
        isPartnersListLoading: false,
      };

    case PartnerListTypeAction.GET_PARTNER_USERS_SUCCESS:
      return {
        ...state,
        partnerUsers: action.partnerUsers,
      };

    case PartnerListTypeAction.GET_PARTNER_USERS_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
      };

    case PartnerListTypeAction.SELECT_PARTNER:
      return {
        ...state,
        selectedPartner: action.selectedPartner,
      };  
      
    case PartnerListTypeAction.CREATE_PARTNER_REQUEST:
      return {
        ...state,
        isPartnerAdding: true,
      }; 

    case PartnerListTypeAction.CREATE_PARTNER_SUCCESS:      
      state.partnersList.push(action.createdPartner);
      message.success(action.messageText, 4);
      return {
        ...state,
        isPartnerAdding: false,
        selectedPartner: action.createdPartner,
      }; 

    case PartnerListTypeAction.CREATE_PARTNER_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isPartnerAdding: false,
      }; 

    case PartnerListTypeAction.EDIT_PARTNER_REQUEST:
      return {
        ...state,
        isPartnerEditing: true,
      };

    case PartnerListTypeAction.EDIT_PARTNER_SUCCESS:
      const indexEditedPartner = state.partnersList
        .findIndex((partner: Partner) => partner.id === action.editedPartner.id);
      state.partnersList.splice(indexEditedPartner, 1);
      state.partnersList.push(action.editedPartner);
      message.success(action.messageText, 4);
      return {
        ...state,
        isPartnerEditing: false,
        selectedPartner: action.editedPartner,
      };

    case PartnerListTypeAction.EDIT_PARTNER_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isPartnerEditing: false,
      };

    case PartnerListTypeAction.REMOVE_PARTNER_REQUEST:
      return {
        ...state,
        isPartnersListLoading: true,
      };

    case PartnerListTypeAction.REMOVE_PARTNER_SUCCESS:
      const indexRemovedPartner = state.partnersList
        .findIndex((partner: Partner) => partner.id === action.removedPartnerId);
      state.partnersList.splice(indexRemovedPartner, 1);
      const selectedPartner = state.partnersList[0] ? state.partnersList[0] : undefined;
      message.success(action.messageText, 4);
      return {
        ...state,
        selectedPartner: selectedPartner,
        isPartnersListLoading: false,
      };

    case PartnerListTypeAction.REMOVE_PARTNER_ERROR:
      message.error(action.messageText, 4);
      return {
        ...state,
        isPartnersListLoading: false,
      };
    default: return state;
  }
};
