import jwt from 'jsonwebtoken';

import { LOGIN_PATH } from '../config/api';
import Http from '../utils/http';
import { ICurrentUser, ILoginUser } from '../types';
import { IRoute, LocaleStorageItem, Routes } from '../constants';
import { get } from 'lodash';

const http = new Http();

class AuthService {

  public login(user: ILoginUser): Promise<ICurrentUser> {

    const login = user.login;
    const password = user.password;
    return http.post(LOGIN_PATH, { login, password })
      .then(this.handleResponse)
      .then((userToken: string) => {

        try {
          const decodedToken: any = jwt.decode(userToken);

          const currentUser: ICurrentUser = {
            userName: login,
            userToken: userToken,
            userRoles: decodedToken.userRoles || [],
            userPermissions: decodedToken.userPermissions || [],
            partnerEndpointGroupId: decodedToken.partnerEndpointGroupId
          };

          this.setCurrentUser(currentUser);
          return currentUser;

        } catch (error) {
          throw Error(`An error occurred while logging in. Error: ${error}`);
        }
      }
      );
  }

  public logout() {
    localStorage.removeItem(LocaleStorageItem.USER);
  }

  public getCurrentUser(): ICurrentUser | null {

    const userFromLocaleStorage = localStorage.getItem(LocaleStorageItem.USER) || '';

    if (!userFromLocaleStorage) {
      return null;
    }

    try {
      return JSON.parse(userFromLocaleStorage) as ICurrentUser;
    } catch (error) {
      throw Error(`There is an error while getting current user. Error: ${error}`);
    }
  }

  public userIsInRole(role: string): boolean {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.userRoles.some((userRole: string) => userRole === role);
    }

    return false;
  }

  public userHasPermission(permission: string): boolean {
    const currentUser = this.getCurrentUser();
    if (currentUser && currentUser.userPermissions) {
      return currentUser.userPermissions.some((item: string) => item === permission);
    }

    return false;
  }

  public checkRouteRestrictions(restriction: string): boolean {
    const currentUser = this.getCurrentUser();

    if (currentUser && currentUser.userPermissions) {
      return currentUser.userPermissions.some((item: string) => item === restriction);
    }

    return false;
  }

  public getCurrentUserRoutes(): IRoute[] {
    return Routes.filter(route => {
      const isPublicRoute = route.accessControl === undefined;
      const roles: [] = get(route, 'accessControl.roles', []);
      const permissions: [] = get(route, 'accessControl.permissions', []);
      const restrictions: [] = get(route, 'accessControl.restrictions', []);

      const isRestrictedRoute = restrictions.some(
        restriction => this.checkRouteRestrictions(restriction)
      );

      const userIsInRole = roles.some(role => this.userIsInRole(role));
      const userHasPermission = permissions.some(permission => this.userHasPermission(permission));

      return !isRestrictedRoute && (isPublicRoute || userIsInRole || userHasPermission);
    });
  }

  private setCurrentUser(currentUser: ICurrentUser) {
    if (!currentUser) {
      throw Error(`currentUser is not defined.`);
    }

    localStorage.setItem(LocaleStorageItem.USER, JSON.stringify(currentUser));
  }

  private handleResponse(response: Response) {
    return response.text().then((text: string) => {
      if (!response.ok) {
        const error = `${response.status} ${response.statusText}`;
        return Promise.reject(error);
      }

      return text;
    });
  }
}

export const authService = new AuthService();
