import { AnyAction } from 'redux';
import { Partner, User } from '../models';

export enum PartnerListTypeAction {
  GET_PARTNERS_LIST_REQUEST = 'GET_LIST_PARTNER_REQUEST',
  GET_PARTNERS_LIST_SUCCESS = 'GET_LIST_PARTNER_SUCCESS',
  GET_PARTNERS_LIST_ERROR = 'GET_LIST_PARTNER_ERROR',
  GET_PARTNER_USERS_SUCCESS = 'GET_PARTNER_USERS_SUCCESS',
  GET_PARTNER_USERS_ERROR = 'GET_PARTNER_USERS_ERROR',
  SELECT_PARTNER = 'SELECT_PARTNER',
  CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST',
  CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS',
  CREATE_PARTNER_ERROR = 'CREATE_PARTNER_ERROR',
  EDIT_PARTNER_REQUEST = 'EDIT_PARTNER_REQUEST',
  EDIT_PARTNER_SUCCESS = 'EDIT_PARTNER_SUCCESS',
  EDIT_PARTNER_ERROR = 'EDIT_PARTNER_ERROR',
  REMOVE_PARTNER_REQUEST = 'REMOVE_PARTNER_REQUEST',
  REMOVE_PARTNER_SUCCESS = 'REMOVE_PARTNER_SUCCESS',
  REMOVE_PARTNER_ERROR = 'REMOVE_PARTNER_ERROR',
}

export interface IPartnersListState {
  partnersList: Partner[];
  partnerUsers: User[];
  selectedPartner: Partner | undefined;
  isPartnersListLoading: boolean;  
  isPartnerAdding: boolean; 
  isPartnerEditing: boolean;
}

export interface IGetPartnersListRequestAction extends AnyAction {
  type: PartnerListTypeAction.GET_PARTNERS_LIST_REQUEST;
}

export interface IGetPartnersListSuccessAction extends AnyAction {
  type: PartnerListTypeAction.GET_PARTNERS_LIST_SUCCESS;
  partnersList: Partner[];
}

export interface IGetPartnersListErrorAction extends AnyAction {
  type: PartnerListTypeAction.GET_PARTNERS_LIST_ERROR;
  messageText: string;
}

export interface IGetPartnerUsersSuccessAction extends AnyAction {
  type: PartnerListTypeAction.GET_PARTNER_USERS_SUCCESS;
  partnerUsers: User[];
}

export interface IGetPartnerUsersErrorAction extends AnyAction {
  type: PartnerListTypeAction.GET_PARTNER_USERS_ERROR;
  messageText: string;
}

export interface ISelectPartnerAction extends AnyAction {
  type: PartnerListTypeAction.SELECT_PARTNER;
  selectedPartner: Partner | undefined; 
}

export interface ICreatePartnerRequestAction extends AnyAction {
  type: PartnerListTypeAction.CREATE_PARTNER_REQUEST;
}

export interface ICreatePartnerSuccessAction extends AnyAction {
  type: PartnerListTypeAction.CREATE_PARTNER_SUCCESS;
  createdPartner: Partner;
  messageText: string;
}

export interface ICreatePartnerErrorAction extends AnyAction {
  type: PartnerListTypeAction.CREATE_PARTNER_ERROR;
  messageText: string;
}

export interface IEditPartnerRequestAction extends AnyAction {
  type: PartnerListTypeAction.EDIT_PARTNER_REQUEST;
}

export interface IEditPartnerSuccessAction extends AnyAction {
  type: PartnerListTypeAction.EDIT_PARTNER_SUCCESS;
  editedPartner: Partner;
  messageText: string;
}

export interface IEditPartnerErrorAction extends AnyAction {
  type: PartnerListTypeAction.EDIT_PARTNER_ERROR; 
  messageText: string;
}

export interface IRemovePartnerRequestAction extends AnyAction {
  type: PartnerListTypeAction.REMOVE_PARTNER_REQUEST;
}

export interface IRemovePartnerSuccessAction extends AnyAction {
  type: PartnerListTypeAction.REMOVE_PARTNER_SUCCESS;
  removedPartnerId: number;
  messageText: string;
}

export interface IRemovePartnerErrorAction extends AnyAction {
  type: PartnerListTypeAction.REMOVE_PARTNER_ERROR;
  messageText: string;
}

export type IPartnersListAction = 
  IGetPartnersListRequestAction | 
  IGetPartnersListSuccessAction |
  IGetPartnersListErrorAction | 
  IGetPartnerUsersSuccessAction |
  IGetPartnerUsersErrorAction |
  ISelectPartnerAction |
  ICreatePartnerRequestAction | 
  ICreatePartnerSuccessAction | 
  ICreatePartnerErrorAction |
  IEditPartnerRequestAction | 
  IEditPartnerSuccessAction |
  IEditPartnerErrorAction | 
  IRemovePartnerRequestAction |
  IRemovePartnerSuccessAction | 
  IRemovePartnerErrorAction;