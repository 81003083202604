import * as React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Icon, Menu, Dropdown } from 'antd';
import { ClickParam } from 'antd/es/menu';
import autobind from 'autobind-decorator';

import { RoutePath } from './../../../constants';
import { authService } from '../../../services';
import { ICurrentUser } from '../../../types';
import { NavigationMenu } from '../NavigationMenu';

const AXSLogo = require('../../../styles/axs-white.png');

const { Header: AntHeader } = Layout;

interface IHeaderState {
  currentPage: string;
  currentUser: ICurrentUser | null;
}

export class Header extends React.Component<{}, IHeaderState> {
  constructor(props: any) {
    super(props);

    const currentUser = authService.getCurrentUser();

    this.state = {
      currentPage: location.pathname,
      currentUser: currentUser,
    };
  }

  render() {
    const { currentPage, currentUser } = this.state;

    return (
      <AntHeader className="header">
        <Row>
          <Col className="logo" span={8}>
            <Link to={RoutePath.LOGIN}>
              <img src={AXSLogo} alt="AXS Logo"/>
              <p>DISTRIBUTION PLATFORM</p>
            </Link>
          </Col>
          <Col className="user_menu" span={16}>
            <Col span={10} offset={8}>
              {
                <NavigationMenu
                  currentPage={currentPage}
                  handleClick={this.handleClick}
                />
              }
            </Col>
            <Col span={6}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="3" onClick={this.logout}>Logout</Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <a className="ant-dropdown-link" href="#">
                  {currentUser ? currentUser.userName : ''} <Icon type="down" />
                </a>
              </Dropdown>
            </Col>
          </Col>
        </Row>
      </AntHeader>
    );
  }

  @autobind
  private handleClick(param: ClickParam): void {
    this.setState({
      currentPage: param.key
    });
  }

  @autobind
  private logout(): void {
    authService.logout();
    location.reload();
  }
}

export default Header;
