import { IOperation } from '../types';

export class ApiEndpoint {
  id: string;
  isAllowed: boolean;
  operations: IOperation[];

  constructor(id: string, isAllowed: boolean, operations: IOperation[]) {
    this.id = id;
    this.isAllowed = isAllowed;
    this.operations = operations;
  }
}

export default ApiEndpoint;
