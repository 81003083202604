import Http from '../utils/http';
import { Offer } from '../models';
import { PARTNER_PATH, OFFER_PATH, CONTEXT_PATH } from '../config/api';

const http = new Http();

class PartnerOfferService {
  public getOffers(partnerId: number): Promise<Offer[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${OFFER_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => this.mapOffers(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public getOffersRelatedToContext(partnerId: number, contextId: number): Promise<void|Offer[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${CONTEXT_PATH}/${contextId}${OFFER_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json().then(data => this.mapOffers(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public createOffer(partnerId: number, offer: Offer): Promise<number> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${OFFER_PATH}`,
      {
        'contextId': offer.contextId,
        'offerId': offer.offerId
      }
    )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public deleteOffer(partnerId: number, offerId: number): Promise<void> {
    return http.delete(`${PARTNER_PATH}/${partnerId}${OFFER_PATH}/${offerId}`)
    .then((response: Response) => {
      if (!response.ok) {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  private mapOffer(offer: any): Offer {
    return {
      id: offer.id,
      contextId: offer.contextId,
      offerId: offer.offerId
    };
  }

  private mapOffers(data: any) {
    return data.map((item: any) => this.mapOffer(item));
  }
}

export const partnerOfferService = new PartnerOfferService();
