import { 
    ApiDocTypeAction, 
    IApiDocState,
    IApiDocAction 
} from './../types';
  
const initialState: IApiDocState = {
    doc: undefined,
    isLoading: false,
    isError: false,
  };

const updatesMap = new Map();
updatesMap.set(ApiDocTypeAction.API_DOC_RECEIVE_INIT, (action: any) => ({
  doc: undefined,
  isLoading: true,
  isError: false,
}));
updatesMap.set(ApiDocTypeAction.API_DOC_RECEIVE_SUCCESS, (action: any) => ({
  doc: action.doc,
  isLoading: false,
}));
updatesMap.set(ApiDocTypeAction.API_DOC_RECEIVE_FAILED, (action: any) => ({
  doc: undefined,
  isLoading: false,
  isError: true
}));

const getUpdate = (action: IApiDocAction): object => {
  if (updatesMap.has(action.type)) {
    return updatesMap.get(action.type)(action);
  }
  return initialState;
};

export const apiDocReducer = 
(state: IApiDocState = initialState, action: IApiDocAction): IApiDocState => {
    const update = getUpdate(action);
    const updatedState = Object.assign({...state}, update);
    return updatedState;
  };
  