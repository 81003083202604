import { AnyAction } from 'redux';

export enum AlertTypeAction {
  ALERT_SUCCESS = 'ALERT_SUCCESS',
  ALERT_ERROR = 'ALERT_ERROR',
  ALERT_INFO = 'ALERT_INFO',
  ALERT_WARNING = 'ALERT_WARNING',
  ALERT_CLEAR = 'ALERT_CLEAR',
}

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface IAlertState {
  alertType: AlertType;
  message: string;
  isAlertShowed: boolean;
}

export interface IAlertAction extends AnyAction {
  type: string;
  alertType: AlertType;
  message: string;
  isAlertShowed: boolean;
}