import { AnyAction } from 'redux';
import { ContextProperty } from '../models';

export enum ContextPropertyTypeAction {
  GET_CONTEXT_PROPERTIES_REQUEST = 'GET_CONTEXT_PROPERTIES_REQUEST',
  GET_CONTEXT_PROPERTIES_SUCCESS = 'GET_CONTEXT_PROPERTIES_SUCCESS',
  GET_CONTEXT_PROPERTIES_ERROR = 'GET_CONTEXT_PROPERTIES_ERROR',
  ADD_CONTEXT_PROPERTY_REQUEST = 'ADD_CONTEXT_PROPERTY_REQUEST',
  ADD_CONTEXT_PROPERTY_SUCCESS = 'ADD_CONTEXT_PROPERTY_SUCCESS',
  ADD_CONTEXT_PROPERTY_ERROR = 'ADD_CONTEXT_PROPERTY_ERROR',
  EDIT_CONTEXT_PROPERTY_REQUEST = 'EDIT_CONTEXT_PROPERTY_REQUEST',
  EDIT_CONTEXT_PROPERTY_SUCCESS = 'EDIT_CONTEXT_PROPERTY_SUCCESS',
  EDIT_CONTEXT_PROPERTY_ERROR = 'EDIT_CONTEXT_PROPERTY_ERROR',
  REMOVE_CONTEXT_PROPERTY_REQUEST = 'REMOVE_CONTEXT_PROPERTY_REQUEST',
  REMOVE_CONTEXT_PROPERTY_SUCCESS = 'REMOVE_CONTEXT_PROPERTY_SUCCESS',
  REMOVE_CONTEXT_PROPERTY_ERROR = 'REMOVE_CONTEXT_PROPERTY_ERROR',
  SELECT_CONTEXT_PROPERTY = 'SELECT_CONTEXT_PROPERTY',
}

export interface IContextPropertyState {
  contextPropertiesList: ContextProperty[];
  selectedContextProperty: ContextProperty | undefined;
  isContextPropertiesLoading: boolean;
  isContextPropertyAdding: boolean;
  isContextPropertyEditing: boolean;
}

export interface ISelectContextProperty {
  type: ContextPropertyTypeAction.SELECT_CONTEXT_PROPERTY;
  selectedContextProperty: ContextProperty;
}

export interface IGetContextPropertiesRequestAction extends AnyAction {
  type: ContextPropertyTypeAction.GET_CONTEXT_PROPERTIES_REQUEST;
}

export interface IGetContextPropertiesSuccessAction extends AnyAction {
  type: ContextPropertyTypeAction.GET_CONTEXT_PROPERTIES_SUCCESS;
  contextPropertiesList: ContextProperty[];
}

export interface IGetContextPropertiesErrorAction extends AnyAction {
  type: ContextPropertyTypeAction.GET_CONTEXT_PROPERTIES_ERROR;
  messageText: string;
}

export interface IAddContextPropertyRequestAction extends AnyAction {
  type: ContextPropertyTypeAction.ADD_CONTEXT_PROPERTY_REQUEST;
}

export interface IAddContextPropertySuccessAction extends AnyAction {
  type: ContextPropertyTypeAction.ADD_CONTEXT_PROPERTY_SUCCESS;
  addedContextProperty: ContextProperty;
  messageText: string;
}

export interface IAddContextPropertyErrorAction extends AnyAction {
  type: ContextPropertyTypeAction.ADD_CONTEXT_PROPERTY_ERROR;
  messageText: string;
}

export interface IEditContextPropertyRequestAction extends AnyAction {
  type: ContextPropertyTypeAction.EDIT_CONTEXT_PROPERTY_REQUEST;
}

export interface IEditContextPropertySuccessAction extends AnyAction {
  type: ContextPropertyTypeAction.EDIT_CONTEXT_PROPERTY_SUCCESS;
  editedContextProperty: ContextProperty;
  messageText: string;
}

export interface IEditContextPropertyErrorAction extends AnyAction {
  type: ContextPropertyTypeAction.EDIT_CONTEXT_PROPERTY_ERROR;
  messageText: string;
}

export interface IRemoveContextPropertyRequestAction extends AnyAction {
  type: ContextPropertyTypeAction.REMOVE_CONTEXT_PROPERTY_REQUEST;
}

export interface IRemoveContextPropertySuccessAction extends AnyAction {
  type: ContextPropertyTypeAction.REMOVE_CONTEXT_PROPERTY_SUCCESS;
  removedContextPropertyId: number;
  messageText: string;
}

export interface IRemoveContextPropertyErrorAction extends AnyAction {
  type: ContextPropertyTypeAction.REMOVE_CONTEXT_PROPERTY_ERROR;
  messageText: string;
}

export type IContextPropertyListAction = 
  ISelectContextProperty |
  IGetContextPropertiesRequestAction |
  IGetContextPropertiesSuccessAction |
  IGetContextPropertiesErrorAction |
  IAddContextPropertyRequestAction | 
  IAddContextPropertySuccessAction | 
  IAddContextPropertyErrorAction | 
  IEditContextPropertyRequestAction | 
  IEditContextPropertySuccessAction | 
  IEditContextPropertyErrorAction |
  IRemoveContextPropertyRequestAction |
  IRemoveContextPropertySuccessAction |
  IRemoveContextPropertyErrorAction;