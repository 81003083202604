import { PARTNER_PATH, CONTEXT_PROPERTY_PATH } from '../config/api';
import Http from '../utils/http';
import { ContextProperty } from '../models';

const http = new Http();

class ContextPropertyService {
  public getContextProperties(partnerId: number): Promise<ContextProperty[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${CONTEXT_PROPERTY_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => this.mapContextProperties(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public getContextProperty(partnerId: number, 
                            contextPropertyId: number ): Promise<ContextProperty> {
    return http.get(`${PARTNER_PATH}/${partnerId}${CONTEXT_PROPERTY_PATH}/${contextPropertyId}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => this.mapContextProperty(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public createContextProperty(partnerId: number, 
                               contextProperty: ContextProperty): Promise<number> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${CONTEXT_PROPERTY_PATH}`,
      {
        'contextId': contextProperty.contextId.toString(),
        'cancellationStatusCode': contextProperty.cancellationStatusCode.toString(),
        'reseatStatusCode': contextProperty.reseatStatusCode.toString(),
      }
    )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public updateContextProperty(partnerId: number,
                               contextProperty: ContextProperty): Promise<void> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${CONTEXT_PROPERTY_PATH}/${contextProperty.id}`,
      {
        'contextId': contextProperty.contextId.toString(),
        'cancellationStatusCode': contextProperty.cancellationStatusCode.toString(),
        'reseatStatusCode': contextProperty.reseatStatusCode.toString(),
      }
    )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });  
  }

  public deleteContextProperty(partnerId: number, contextPropertyId: number): Promise<void> {
    return http.delete(`${PARTNER_PATH}/${partnerId}${CONTEXT_PROPERTY_PATH}/${contextPropertyId}`)
    .then((response: Response) => {
      if (!response.ok) {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  private mapContextProperty(contextProperty: any): ContextProperty {
    return {
      id: contextProperty.id,
      contextId: contextProperty.contextId,
      cancellationStatusCode: contextProperty.cancellationStatusCode,
      reseatStatusCode: contextProperty.reseatStatusCode,
    };
  }

  private mapContextProperties(data: any): ContextProperty[] {
    return data.map((item: any) => this.mapContextProperty(item));
  }
}

export const contextPropertyService = new ContextPropertyService();
