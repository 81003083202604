import * as React from 'react';
import { Layout } from 'antd';

import { Footer } from '../../components/Common';
import { AlertContainer } from '../../containers/Shared';
import { LoginContainer } from '../../containers/LoginForm';

import './../../styles/index.less';

export class LoginPage extends React.Component {
  
  render() {
    return (
      <Layout> 
        <AlertContainer /> 
        <LoginContainer />    
        <Footer/>
      </Layout>
    );
  }
}
