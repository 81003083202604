import { AnyAction } from 'redux';
import { User } from '../models';

export enum UserManagerTypeAction {
  GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST',
  GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS',
  GET_USERS_LIST_ERROR = 'GET_USERS_LIST_ERROR',
  SELECT_USER = 'SELECT_USER',
  OPEN_CREATING_USER_WINDOW = 'OPEN_CREATING_USER_WINDOW',
  CLOSE_CREATING_USER_WINDOW = 'CLOSE_CREATING_USER_WINDOW',
  OPEN_RESET_PASSWORD_WINDOW = 'OPEN_RESET_PASSWORD_WINDOW',
  CLOSE_RESET_PASSWORD_WINDOW = 'CLOSE_RESET_PASSWORD_WINDOW',
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
  REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST',
  REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS',
  REMOVE_USER_ERROR = 'REMOVE_USER_ERROR',
  RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST',
  RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS',
  RESET_USER_PASSWORD_ERROR = 'RESET_USER_PASSWORD_ERROR',
}

export interface IUserManagerState {
  usersList: User[];
  selectedUser: User | undefined;
  isUserListLoading: boolean;
  isUserAdding: boolean;
  isCreateModalOpen: boolean;
  isUserUpdating: boolean;
  isResetPasswordModalOpen: boolean;
}

export interface IGetUsersListRequestAction extends AnyAction {
  type: UserManagerTypeAction.GET_USERS_LIST_REQUEST;
}

export interface IGetUsersListSuccessAction extends AnyAction {
  type: UserManagerTypeAction.GET_USERS_LIST_SUCCESS;
  usersList: User[];
}

export interface IGetUsersListErrorAction extends AnyAction {
  type: UserManagerTypeAction.GET_USERS_LIST_ERROR;
  messageText: string;
}

export interface ISelectUserAction extends AnyAction {
  type: UserManagerTypeAction.SELECT_USER;
  selectedUser: User | undefined; 
}

export interface IOpenCreatingUserWindow extends AnyAction {
  type: UserManagerTypeAction.OPEN_CREATING_USER_WINDOW;
}

export interface ICloseCreatingUserWindow extends AnyAction {
  type: UserManagerTypeAction.CLOSE_CREATING_USER_WINDOW;
}

export interface IOpenResetPasswordWindow extends AnyAction {
  type: UserManagerTypeAction.OPEN_RESET_PASSWORD_WINDOW;
}

export interface ICloseResetPasswordWindow extends AnyAction {
  type: UserManagerTypeAction.CLOSE_RESET_PASSWORD_WINDOW;
}

export interface ICreateUserRequestAction extends AnyAction {
  type: UserManagerTypeAction.CREATE_USER_REQUEST;
}

export interface ICreateUserSuccessAction extends AnyAction {
  type: UserManagerTypeAction.CREATE_USER_SUCCESS;
  createdUser: User;
  messageText: string;
}

export interface ICreateUserErrorAction extends AnyAction {
  type: UserManagerTypeAction.CREATE_USER_ERROR;
  messageText: string;
}

export interface IRemoveUserRequestAction extends AnyAction {
  type: UserManagerTypeAction.REMOVE_USER_REQUEST;
}

export interface IRemoveUserSuccessAction extends AnyAction {
  type: UserManagerTypeAction.REMOVE_USER_SUCCESS;
  removedUserId: number;
  messageText: string;
}

export interface IRemoveUserErrorAction extends AnyAction {
  type: UserManagerTypeAction.REMOVE_USER_ERROR;
  messageText: string;
}

export interface IResetUserPasswordRequestAction extends AnyAction {
  type: UserManagerTypeAction.RESET_USER_PASSWORD_REQUEST;
}

export interface IResetUserPasswordSuccessAction extends AnyAction {
  type: UserManagerTypeAction.RESET_USER_PASSWORD_SUCCESS;
  updatedUser: User;
  messageText: string;
}

export interface IResetUserPasswordErrorAction extends AnyAction {
  type: UserManagerTypeAction.RESET_USER_PASSWORD_ERROR; 
  messageText: string;
}

export type IUserManagerAction = 
  IGetUsersListRequestAction |
  IGetUsersListSuccessAction |
  IGetUsersListErrorAction |
  ISelectUserAction |
  IOpenCreatingUserWindow |
  ICloseCreatingUserWindow |
  ICreateUserRequestAction |
  ICreateUserSuccessAction |
  ICreateUserErrorAction |
  IRemoveUserRequestAction |
  IRemoveUserSuccessAction |
  IRemoveUserErrorAction |
  IResetUserPasswordRequestAction |
  IResetUserPasswordSuccessAction |
  IResetUserPasswordErrorAction |
  IOpenResetPasswordWindow |
  ICloseResetPasswordWindow;