import { PARTNER_PATH, CONTEXT_PATH } from '../config/api';
import Http from '../utils/http';
import { Context } from '../models';

const http = new Http();

class PartnerContextService {
  public getContexts(partnerId: number): Promise<Context[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${CONTEXT_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => this.mapContexts(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public createContext(partnerId: number, context: Context): Promise<number> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${CONTEXT_PATH}`,
      {
        'contextId': context.contextId.toString()
      }
    )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public deleteContext(partnerId: number, contextId: number): Promise<void> {
    return http.delete(`${PARTNER_PATH}/${partnerId}${CONTEXT_PATH}/${contextId}`)
    .then((response: Response) => {
      if (!response.ok) {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  private mapContext(context: any): Context {
    return {
      id: context.id,
      contextId: context.contextId
    };
  }

  private mapContexts(data: any): Context[] {
    return data.map((item: any) => this.mapContext(item));
  }
}

export const partnerContextService = new PartnerContextService();
