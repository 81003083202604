import * as React from 'react';
import { Layout } from 'antd';

import './../styles/index.less';
import { AlertContainer } from '../containers/Shared';
import { Footer, Header } from '../components/Common';

export class PageLayout extends React.Component {

  render() {
    return (
      <Layout>
        <AlertContainer />
        <Header />
        {this.props.children}
        <Footer/>
      </Layout>
    );
  }
}
