export const API_PATH = '/api';
export const LOGIN_PATH = '/account/login';
export const PARTNER_PATH = '/partner';
export const PARTNER_GROUP_PATH = `${PARTNER_PATH}/endpoint-groups`;
export const CONTEXT_PATH = '/context';
export const CONTEXTS_PATH = '/contexts';
export const CONTEXT_PROPERTY_PATH = '/context-properties';
export const PRICING_PATH = '/pricing';
export const OUTLET_PATH = '/outlet';
export const OFFER_PATH = '/offer';
export const MEMBER_PATH = '/member';
export const DOC_PATH = '/doc';
export const COMPOSITE_PURCHASE_DOC = '/composite-purchase';
export const EXTERNAL_PURCHASE_DOC = '/external-purchase';
export const RESALE_DOC = '/resale';
export const FULFILLMENT_DOC = '/fulfillment';
export const TRANSFER_API_DOC = '/transfer-api';
export const PUSH_INVENTORY_API_DOC = '/push-inventory-api';
export const RECALL_API_DOC = '/recall-api';
export const PRICING_API_DOC = '/pricing-api';
export const AC2_SERVICE_DOC = '/ac2-service';
export const ENDPOINTS_PATH = '/endpoints';
export const USER_PATH = '/user';
