import { PARTNER_PATH, PRICING_PATH, CONTEXTS_PATH } from '../config/api';
import Http from '../utils/http';
import { Context } from '../models';

const http = new Http();

class PricingApiService {
  public getContexts(partnerId: number): Promise<Context[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${PRICING_PATH}${CONTEXTS_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => this.mapContexts(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public getContext(partnerId: number, contextId: number ): Promise<Context> {
    return http.get(`${PARTNER_PATH}/${partnerId}${PRICING_PATH}${CONTEXTS_PATH}/${contextId}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => this.mapContext(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public createContext(partnerId: number, context: Context): Promise<number> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${PRICING_PATH}${CONTEXTS_PATH}`,
      {
        'contextId': context.contextId.toString(),
      }
    )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public updateContext(partnerId: number, context: Context): Promise<void> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${PRICING_PATH}${CONTEXTS_PATH}/${context.id}`,
      {
        'contextId': context.contextId.toString(),
      }
    )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
          .then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });  
  }

  public deleteContext(partnerId: number, contextId: number): Promise<void> {
    return http.delete(`${PARTNER_PATH}/${partnerId}${PRICING_PATH}${CONTEXTS_PATH}/${contextId}`)
    .then((response: Response) => {
      if (!response.ok) {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  private mapContext(context: any): Context {
    return {
      id: context.id,
      contextId: context.contextId,
    };
  }

  private mapContexts(data: any): Context[] {
    return data.map((item: any) => this.mapContext(item));
  }
}

export const pricingApiService = new PricingApiService();
