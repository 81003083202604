import Http from '../utils/http';
import { Outlet } from '../models';
import { PARTNER_PATH, OUTLET_PATH, CONTEXT_PATH } from '../config/api';

const http = new Http();

class PartnerOutletService {
  public getOutlets(partnerId: number): Promise<Outlet[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${OUTLET_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json().then(data => this.mapOutlets(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public getOutletsRelatedToContext(partnerId: number, contextId: number): Promise<void|Outlet[]> {
    return http.get(`${PARTNER_PATH}/${partnerId}${CONTEXT_PATH}/${contextId}${OUTLET_PATH}`)
    .then((response: Response) => {
      if (response.ok) {
        return response.json().then(data => this.mapOutlets(data));
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public addOutlet(partnerId: number, outlet: Outlet): Promise<number> {
    return http.post(
      `${PARTNER_PATH}/${partnerId}${OUTLET_PATH}`,
      {
        'contextId': outlet.contextId,
        'outletId': outlet.outletId
      }
    ).then((response: Response) => {
      if (response.ok) {
        return response.json().then(data => data[0]);
      } else {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  public deleteOutlet(partnerId: number, outletId: number): Promise<void> {
    return http.delete(`${PARTNER_PATH}/${partnerId}${OUTLET_PATH}/${outletId}`)
    .then((response: Response) => {
      if (!response.ok) {
        throw Error(`${response.status} ${response.statusText}`);
      }
    });
  }

  private mapOutlet(outlet: any): Outlet {
    return {
      id: outlet.id,
      contextId: outlet.contextId,
      outletId: outlet.outletId
    };
  }

  private mapOutlets(data: any): Outlet[] {
    return data.map((item: any) => this.mapOutlet(item));
  }
}

export const partnerOutletService = new PartnerOutletService();
