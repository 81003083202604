import Http from '../utils/http';
import Member from '../models/Member';
import { PARTNER_PATH, MEMBER_PATH } from '../config/api';

const http = new Http();

class PartnerMemberService {
    public getMembers(partnerId: number): Promise<Member[]> {
        return http.get(`${PARTNER_PATH}/${partnerId}${MEMBER_PATH}`)
        .then((response: Response) => {
          if (response.ok) {
            return response.json()
              .then(data => this.mapMembers(data));
          } else {
            throw Error(`${response.status} ${response.statusText}`);
          }
        });
    }

    public createMember(partnerId: number, member: Member): Promise<number> {
        return http.post(
          `${PARTNER_PATH}/${partnerId}${MEMBER_PATH}`,
          {
            'memberId': member.memberId.toString()
          }
        )
        .then((response: Response) => {
          if (response.ok) {
            return response.json()
              .then(data => data[0]);
          } else {
            throw Error(`${response.status} ${response.statusText}`);
          }
        });
    }

    public deleteMember(partnerId: number, memberId: number): Promise<void> {
        return http.delete(`${PARTNER_PATH}/${partnerId}${MEMBER_PATH}/${memberId}`)
        .then((response: Response) => {
          if (!response.ok) {
            throw Error(`${response.status} ${response.statusText}`);
          }
        });
    }

    private mapMember(member: any): Member {
        return {
          id: member.id,
          memberId: member.memberId
        };
      }
    
    private mapMembers(data: any): Member[] {
        return data.map((item: any) => this.mapMember(item));
    }
}

export const partnerMemberService = new PartnerMemberService();