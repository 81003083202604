import * as React from 'react';
import { Layout } from 'antd';
import { PartnerEndpointGroupsContainer } from '../../containers/PartnerEndpointGroupsManager';
import { PageLayout } from 'src/layouts/PageLayout';

import './../../styles/index.less';

export class PartnerEndpointGroupsPage extends React.Component {

  render() {
    return (
      <PageLayout>
        <Layout className="partner_endpoint_groups_table_page">
          <PartnerEndpointGroupsContainer />
        </Layout>
      </PageLayout>
    );
  }
}
