import { AnyAction } from 'redux';
import { Context, Offer, Outlet } from '../models';

export enum PartnerPermissionsTypeAction {
  GET_PARTNER_CONTEXT_REQUEST = 'GET_PARTNER_CONTEXT_REQUEST',
  GET_PARTNER_CONTEXT_SUCCESS = 'GET_PARTNER_CONTEXT_SUCCESS',
  GET_PARTNER_CONTEXT_ERROR = 'GET_PARTNER_CONTEXT_ERROR',
  ADD_PARTNER_CONTEXT_REQUEST = 'ADD_PARTNER_CONTEXT_REQUEST',
  ADD_PARTNER_CONTEXT_SUCCESS = 'ADD_PARTNER_CONTEXT_SUCCESS',
  ADD_PARTNER_CONTEXT_ERROR = 'ADD_PARTNER_CONTEXT_ERROR',
  REMOVE_PARTNER_CONTEXT_REQUEST = 'REMOVE_PARTNER_CONTEXT_REQUEST',
  REMOVE_PARTNER_CONTEXT_SUCCESS = 'REMOVE_PARTNER_CONTEXT_SUCCESS',
  REMOVE_PARTNER_CONTEXT_ERROR = 'REMOVE_PARTNER_CONTEXT_ERROR',
  SELECT_PARTNER_CONTEXT = 'SELECT_PARTNER_CONTEXT',

  GET_PARTNER_OFFER_REQUEST = 'GET_PARTNER_OFFER_REQUEST',
  GET_PARTNER_OFFER_SUCCESS = 'GET_PARTNER_OFFER_SUCCESS',
  GET_PARTNER_OFFER_ERROR = 'GET_PARTNER_OFFER_ERROR',
  ADD_PARTNER_OFFER_REQUEST = 'ADD_PARTNER_OFFER_REQUEST',
  ADD_PARTNER_OFFER_SUCCESS = 'ADD_PARTNER_OFFER_SUCCESS',
  ADD_PARTNER_OFFER_ERROR = 'ADD_PARTNER_OFFER_ERROR',
  REMOVE_PARTNER_OFFER_REQUEST = 'REMOVE_PARTNER_OFFER_REQUEST',
  REMOVE_PARTNER_OFFER_SUCCESS = 'REMOVE_PARTNER_OFFER_SUCCESS',
  REMOVE_PARTNER_OFFER_ERROR = 'REMOVE_PARTNER_OFFER_ERROR',
  SELECT_PARTNER_OFFER = 'SELECT_PARTNER_OFFER',

  GET_PARTNER_OUTLET_REQUEST = 'GET_PARTNER_OUTLET_REQUEST',
  GET_PARTNER_OUTLET_SUCCESS = 'GET_PARTNER_OUTLET_SUCCESS',
  GET_PARTNER_OUTLET_ERROR = 'GET_PARTNER_OUTLET_ERROR',
  ADD_PARTNER_OUTLET_REQUEST = 'ADD_PARTNER_OUTLET_REQUEST',
  ADD_PARTNER_OUTLET_SUCCESS = 'ADD_PARTNER_OUTLET_SUCCESS',
  ADD_PARTNER_OUTLET_ERROR = 'ADD_PARTNER_OUTLET_ERROR',
  REMOVE_PARTNER_OUTLET_REQUEST = 'REMOVE_PARTNER_OUTLET_REQUEST',
  REMOVE_PARTNER_OUTLET_SUCCESS = 'REMOVE_PARTNER_OUTLET_SUCCESS',
  REMOVE_PARTNER_OUTLET_ERROR = 'REMOVE_PARTNER_OUTLET_ERROR',
  SELECT_PARTNER_OUTLET = 'SELECT_PARTNER_OUTLET',
}

export interface IPartnerPermissionsState {
  partnerContextList: Context[];
  partnerOfferList: Offer[];
  partnerOutletList: Outlet[];
  selectedPartnerContext: Context | undefined;
  selectedPartnerOffer: Offer | undefined;
  selectedPartnerOutlet: Outlet | undefined;
  isPartnerContextsLoading: boolean;
  isPartnerOffersLoading: boolean;
  isPartnerOutletsLoading: boolean;
  isPartnerContextAdding: boolean;
  isPartnerOfferAdding: boolean;
  isPartnerOutletAdding: boolean;
}

export interface ISelectPartnerContext {
  type: PartnerPermissionsTypeAction.SELECT_PARTNER_CONTEXT;
  selectedPartnerContext: Context;
}

export interface ISelectPartnerOffer {
  type: PartnerPermissionsTypeAction.SELECT_PARTNER_OFFER;
  selectedPartnerOffer: Offer;
}

export interface ISelectPartnerOutlet {
  type: PartnerPermissionsTypeAction.SELECT_PARTNER_OUTLET;
  selectedPartnerOutlet: Outlet;
}

export interface IGetPartnerContextsRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_CONTEXT_REQUEST;
}

export interface IGetPartnerOffersRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_OFFER_REQUEST;
}

export interface IGetPartnerOutletsRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_OUTLET_REQUEST;
}

export interface IGetPartnerContextsSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_CONTEXT_SUCCESS;
  partnerContextList: Context[];
}

export interface IGetPartnerOffersSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_OFFER_SUCCESS;
  partnerOfferList: Offer[];
}

export interface IGetPartnerOutletsSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_OUTLET_SUCCESS;
  partnerOutletList: Outlet[];
}

export interface IGetPartnerContextsErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_CONTEXT_ERROR;
  messageText: string;
}

export interface IGetPartnerOffersErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_OFFER_ERROR;
  messageText: string;
}

export interface IGetPartnerOutletsErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.GET_PARTNER_OUTLET_ERROR;
  messageText: string;
}

export interface IAddPartnerContextRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_CONTEXT_REQUEST;
}

export interface IAddPartnerOfferRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_OFFER_REQUEST;
}

export interface IAddPartnerOutletRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_OUTLET_REQUEST;
}

export interface IAddPartnerContextSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_CONTEXT_SUCCESS;
  addedPartnerContext: Context;
  messageText: string;
}

export interface IAddPartnerOfferSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_OFFER_SUCCESS;
  addedPartnerOffer: Offer;
  messageText: string;
}

export interface IAddPartnerOutletSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_OUTLET_SUCCESS;
  addedPartnerOutlet: Outlet;
  messageText: string;
}

export interface IAddPartnerContextErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_CONTEXT_ERROR;
  messageText: string;
}

export interface IAddPartnerOfferErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_OFFER_ERROR;
  messageText: string;
}

export interface IAddPartnerOutletErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.ADD_PARTNER_OUTLET_ERROR;
  messageText: string;
}

export interface IRemovePartnerContextRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_CONTEXT_REQUEST;
}

export interface IRemovePartnerOfferRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_OFFER_REQUEST;
}

export interface IRemovePartnerOutletRequestAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_OUTLET_REQUEST;
}

export interface IRemovePartnerContextSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_CONTEXT_SUCCESS;
  removedPartnerContextId: number;
  messageText: string;
}

export interface IRemovePartnerOfferSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_OFFER_SUCCESS;
  removedPartnerOfferId: number;
  messageText: string;
}

export interface IRemovePartnerOutletSuccessAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_OUTLET_SUCCESS;
  removedPartnerOutletId: number;
  messageText: string;
}

export interface IRemovePartnerContextErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_CONTEXT_ERROR;
  messageText: string;
}

export interface IRemovePartnerOfferErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_OFFER_ERROR;
  messageText: string;
}

export interface IRemovePartnerOutletErrorAction extends AnyAction {
  type: PartnerPermissionsTypeAction.REMOVE_PARTNER_OUTLET_ERROR;
  messageText: string;
}

export type IPartnerPermissionsListAction = 
ISelectPartnerContext |
ISelectPartnerOffer | 
ISelectPartnerOutlet |
IGetPartnerContextsRequestAction |
IGetPartnerOffersRequestAction |
IGetPartnerOutletsRequestAction |
IGetPartnerContextsSuccessAction |
IGetPartnerOffersSuccessAction |
IGetPartnerOutletsSuccessAction |
IGetPartnerContextsErrorAction |
IGetPartnerOffersErrorAction |
IGetPartnerOutletsErrorAction |
IAddPartnerContextRequestAction |
IAddPartnerOfferRequestAction |
IAddPartnerOutletRequestAction |
IAddPartnerContextSuccessAction |
IAddPartnerOfferSuccessAction |
IAddPartnerOutletSuccessAction |
IAddPartnerContextErrorAction |
IAddPartnerOfferErrorAction |
IAddPartnerOutletErrorAction |
IRemovePartnerContextRequestAction |
IRemovePartnerOfferRequestAction |
IRemovePartnerOutletRequestAction |
IRemovePartnerContextSuccessAction |
IRemovePartnerOfferSuccessAction |
IRemovePartnerOutletSuccessAction |
IRemovePartnerContextErrorAction |
IRemovePartnerOfferErrorAction |
IRemovePartnerOutletErrorAction;
