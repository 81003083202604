import { USER_PATH } from '../config/api';
import Http from '../utils/http';
import User from '../models/UserManager/User';
import ICreateUser from '../models/UserManager/ICreateUser';

const http = new Http();

class UserManagerService {
  public getUsers(): Promise<User[]> {
    return http.get(USER_PATH)
      .then((response: Response) => {
        
        if (!response.ok) {
          throw Error(`${response.status} ${response.statusText}`);
        }

        return response.json().then(data => this.mapUsers(data));
      });
  }

  public getUser(id: number): Promise<User> {
    return http.get(`${USER_PATH}/${id}`)
      .then((response: Response) => {

        if (!response.ok) {
          throw Error(`${response.status} ${response.statusText}`);         
        } 

        return response.json().then(data => this.mapUser(data));
      });
  }

  public getUsersByPartnerId(partnerId: number): Promise<User[]> {
    return http.get(`${USER_PATH}/partner/${partnerId}`)
      .then((response: Response) => {
        
        if (!response.ok) {
          throw Error(`${response.status} ${response.statusText}`);
        }

        return response.json().then(data => this.mapUsers(data));
      });
  }

  public addUser(user: ICreateUser): Promise<number> {
    return http.post(
      USER_PATH,
      {
        'userName': user.userName,
        'password': user.password,
        'partnerId': user.partnerId,
      })
      .then((response: Response) => {

        if (!response.ok) {
          return response.text().then((errText: string) => {
              throw Error(`${response.status} ${response.statusText} - ${errText}`);
            });
        }

        return response.json().then(data => data[0]);
      });
  }

  public resetUserPassword(userId: number, newPassword: string): Promise<void> {
    return http.post(
      `${USER_PATH}/reset-password/${userId}`,
      {
        'password': newPassword,
      })
      .then((response: Response) => {

        if (!response.ok) {
          return response.text().then((errText: string) => {
              throw Error(`${response.status} ${response.statusText} - ${errText}`);
            });
        }

        return response.json().then(data => data[0]);
      });
  }

  public deleteUser(id: number): Promise<void> {
    return http.delete(`${USER_PATH}/${id}`)
      .then((response: Response) => {
        if (!response.ok) {
          throw Error(`${response.status} ${response.statusText}`);
        }
      });
  }

  private mapUser(user: any): User {
    return {
      id: user.id,
      name: user.name,
      roles: user.roles,
      partner: user.partner,
    };
  }

  private mapUsers(data: any): User[] {
    return data.map((item: any) => this.mapUser(item));
  }
}

export const userManagerService = new UserManagerService();