import { RouteProps, RouteComponentProps, Redirect, Route } from 'react-router-dom';
import React from 'react';

import { RoutePath } from '../constants';
import { authService } from '../services';

interface IPrivateRouteProps {
  roles?: string[];
  permissions?: string[];
  restrictions?: string[];
}

type PrivateRouteProps = IPrivateRouteProps & RouteProps;

export const PrivateRoute = ({
  component,
  roles,
  permissions,
  restrictions,
  ...rest
}: PrivateRouteProps) => {
  if (!component) {
    throw Error('component is not defined.');
  }

  const currentUser = authService.getCurrentUser();

  if (!currentUser) {
    return <Redirect to={{ pathname: RoutePath.LOGIN }} />;
  }
  
  const userHasAccess = () => {
    const hasRole = roles?.some((role) =>
      currentUser.userRoles.find((userRole) => userRole === role));
    const hasPermission = permissions?.some((permission) =>
      currentUser.userPermissions.find((userPermission) => userPermission === permission));
    const isRestrictedRoute = restrictions?.some((restriction) =>
      currentUser.userPermissions.find((userPermission) => userPermission === restriction));
    return !isRestrictedRoute && (hasRole || hasPermission);
  };

  const Component = component;

  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    
    if (!currentUser.userToken) {
      authService.logout();
      return <Redirect to={{ pathname: RoutePath.LOGIN }} />;
    }
    
    if (!userHasAccess()) {
      authService.logout();
      return <Redirect to={{ pathname: RoutePath.LOGIN }} />;
    }

    return <Component {...props} />;
  };

  return (<Route {...rest} render={render} />);
};
