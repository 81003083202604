import { AnyAction } from 'redux';
import { Member } from '../models';

export enum PartnerMemberTypeAction {
  GET_PARTNER_MEMBER_REQUEST = 'GET_PARTNER_MEMBER_REQUEST',
  GET_PARTNER_MEMBER_SUCCESS = 'GET_PARTNER_MEMBER_SUCCESS',
  GET_PARTNER_MEMBER_ERROR = 'GET_PARTNER_MEMBER_ERROR',
  ADD_PARTNER_MEMBER_REQUEST = 'ADD_PARTNER_MEMBER_REQUEST',
  ADD_PARTNER_MEMBER_SUCCESS = 'ADD_PARTNER_MEMBER_SUCCESS',
  ADD_PARTNER_MEMBER_ERROR = 'ADD_PARTNER_MEMBER_ERROR',
  REMOVE_PARTNER_MEMBER_REQUEST = 'REMOVE_PARTNER_MEMBER_REQUEST',
  REMOVE_PARTNER_MEMBER_SUCCESS = 'REMOVE_PARTNER_MEMBER_SUCCESS',
  REMOVE_PARTNER_MEMBER_ERROR = 'REMOVE_PARTNER_MEMBER_ERROR',
  SELECT_PARTNER_MEMBER = 'SELECT_PARTNER_MEMBER',
}

export interface IPartnerMemberState {
  partnerMemberList: Member[];
  selectedPartnerMember: Member | undefined;
  isPartnerMembersLoading: boolean;
  isPartnerMemberAdding: boolean;
}

export interface ISelectPartnerMember {
  type: PartnerMemberTypeAction.SELECT_PARTNER_MEMBER;
  selectedPartnerMember: Member;
}

export interface IGetPartnerMembersRequestAction extends AnyAction {
  type: PartnerMemberTypeAction.GET_PARTNER_MEMBER_REQUEST;
}

export interface IGetPartnerMembersSuccessAction extends AnyAction {
  type: PartnerMemberTypeAction.GET_PARTNER_MEMBER_SUCCESS;
  partnerMemberList: Member[];
}

export interface IGetPartnerMembersErrorAction extends AnyAction {
  type: PartnerMemberTypeAction.GET_PARTNER_MEMBER_ERROR;
  messageText: string;
}

export interface IAddPartnerMemberRequestAction extends AnyAction {
  type: PartnerMemberTypeAction.ADD_PARTNER_MEMBER_REQUEST;
}

export interface IAddPartnerMemberSuccessAction extends AnyAction {
  type: PartnerMemberTypeAction.ADD_PARTNER_MEMBER_SUCCESS;
  addedPartnerMember: Member;
  messageText: string;
}

export interface IAddPartnerMemberErrorAction extends AnyAction {
  type: PartnerMemberTypeAction.ADD_PARTNER_MEMBER_ERROR;
  messageText: string;
}

export interface IRemovePartnerMemberRequestAction extends AnyAction {
  type: PartnerMemberTypeAction.REMOVE_PARTNER_MEMBER_REQUEST;
}

export interface IRemovePartnerMemberSuccessAction extends AnyAction {
  type: PartnerMemberTypeAction.REMOVE_PARTNER_MEMBER_SUCCESS;
  removedPartnerMemberId: number;
  messageText: string;
}

export interface IRemovePartnerMemberErrorAction extends AnyAction {
  type: PartnerMemberTypeAction.REMOVE_PARTNER_MEMBER_ERROR;
  messageText: string;
}

export type IPartnerMemberListAction =
  ISelectPartnerMember |
  IGetPartnerMembersErrorAction |
  IGetPartnerMembersRequestAction |
  IGetPartnerMembersSuccessAction |
  IAddPartnerMemberErrorAction |
  IAddPartnerMemberRequestAction |
  IAddPartnerMemberSuccessAction |
  IRemovePartnerMemberErrorAction |
  IRemovePartnerMemberRequestAction |
  IRemovePartnerMemberSuccessAction;