import { ExternalPurchaseDocPage } from 'src/pages/ExternalPurchaseDocPage';
import { NotFound } from '../components/Common';
import {
  Ac2ServiceDocPage,
  CompositePurchaseDocPage,
  DocEditPage,
  DocPage,
  FulfillmentDocPage,
  LoginPage,
  PartnerEndpointGroupsPage,
  PartnerPage,
  PricingApiDocPage,
  PushInventoryApiDocPage,
  RecallApiDocPage,
  ResaleDocPage,
  TransferApiDocPage,
  UserManagerPage,
} from '../pages';
import { RoutePath } from './RoutePath';
import { UserPermission } from './UserPermission';
import { UserRole } from './UserRole';

export enum RouteSection {
  DOCUMENTATION = 'Documentation',
  SETTINGS = 'Settings',
}

export interface IRoute {
  path: string;
  component: any;
  exact: boolean;
  accessControl?: {
    roles?: UserRole[];
    permissions?: UserPermission[];
    restrictions?: string[];
  };
  menuSettings?: {
    label: string;
    section?: RouteSection;
  };
}

export const Routes: IRoute[] = [
  {
    exact: true,
    path: RoutePath.LOGIN,
    component: LoginPage,
  },
  {
    exact: true,
    path: RoutePath.DOC,
    component: DocPage,
    accessControl: {
      roles: [UserRole.ADMIN, UserRole.PARTNER],
      restrictions: [
        UserPermission.RESALE_DOC,
        UserPermission.FULFILLMENT_DOC,
        UserPermission.AC2_SERVICE_DOC,
      ],
    },
    menuSettings: {
      label: 'Purchase Flow',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_COMPOSITE_PURCHASE,
    component: CompositePurchaseDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.COMPOSITE_PURCHASE_DOC],
    },
    menuSettings: {
      label: 'Composite Purchase Flow',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_EXTERNAL_PURCHASE,
    component: ExternalPurchaseDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.EXTERNAL_PURCHASE_DOC],
    },
    menuSettings: {
      label: 'External Purchase Flow',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_TRANSFER_API,
    component: TransferApiDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.TRANSFER_API_DOC],
    },
    menuSettings: {
      label: 'Transfer API',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_RESALE,
    component: ResaleDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.RESALE_DOC],
    },
    menuSettings: {
      label: 'AXS ListPro API',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_FULFILLMENT,
    component: FulfillmentDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.FULFILLMENT_DOC],
    },
    menuSettings: {
      label: 'Fulfillment API',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_PUSH_INVENTORY_API,
    component: PushInventoryApiDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.PUSH_INVENTORY_DOC],
    },
    menuSettings: {
      label: 'Push Inventory API',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_RECALL_API,
    component: RecallApiDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.RECALL_API_DOC],
    },
    menuSettings: {
      label: 'Recall API',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_UPDATE_PRICE_CHART_API,
    component: PricingApiDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.PRICING_API_DOC],
    },
    menuSettings: {
      label: 'Pricing API',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_AC2_SERVICE,
    component: Ac2ServiceDocPage,
    accessControl: {
      roles: [UserRole.ADMIN],
      permissions: [UserPermission.AC2_SERVICE_DOC],
    },
    menuSettings: {
      label: 'Access Control',
      section: RouteSection.DOCUMENTATION,
    }
  },
  {
    exact: true,
    path: RoutePath.DOC_EDIT,
    component: DocEditPage,
    accessControl: {
      roles: [UserRole.ADMIN],
    },
    menuSettings: {
      label: 'Docs Manager',
      section: RouteSection.SETTINGS,
    }
  },
  {
    exact: true,
    path: RoutePath.PARTNERS,
    component: PartnerPage,
    accessControl: {
      roles: [UserRole.ADMIN],
    },
    menuSettings: {
      label: 'Partners',
    }
  },
  {
    exact: true,
    path: RoutePath.PARTNER_ENDPOINT_GROUPS,
    component: PartnerEndpointGroupsPage,
    accessControl: {
      roles: [UserRole.ADMIN],
    },
    menuSettings: {
      label: 'Partner Endpoint Groups',
      section: RouteSection.SETTINGS,
    }
  },
  {
    exact: true,
    path: RoutePath.USERS,
    component: UserManagerPage,
    accessControl: {
      roles: [UserRole.ADMIN],
    },
    menuSettings: {
      label: 'User Manager',
      section: RouteSection.SETTINGS,
    }
  },
  {
    exact: false,
    path: RoutePath.UNKNOWN_PATH,
    component: NotFound,
  }
];
