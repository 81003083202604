import * as React from 'react';
import { Layout } from 'antd';
import { UserManagerContainer } from '../../containers/UserManager';
import { PageLayout } from 'src/layouts/PageLayout';

import './../../styles/index.less';

export class UserManagerPage extends React.Component {
  
  render() {
    return (
      <PageLayout>
        <Layout className="users_page">
          <UserManagerContainer />  
        </Layout>
      </PageLayout>
    );
  }
}
