import { get } from 'lodash';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IRoute, Routes } from '../constants';
import { PrivateRoute } from './PrivateRoute';

const getPublicRoute = (route: IRoute) => {
  return (
    <Route
      key={route.path}
      exact={route.exact}
      path={route.path}
      component={route.component}
    />
  );
};

const getPrivateRoute = (route: IRoute) => {
  const roles = get(route, 'accessControl.roles', []);
  const permissions = get(route, 'accessControl.permissions', []);
  const restrictions = get(route, 'accessControl.restrictions', []);

  return (
    <PrivateRoute
      key={route.path}
      exact={route.exact}
      roles={roles}
      permissions={permissions}
      restrictions={restrictions}
      path={route.path}
      component={route.component}
    />
  );
};

export const createRoutes = () => {
  return (
    <Switch>
      {Routes.map((route: IRoute) => {
        const isPrivateRoute = route.accessControl !== undefined;
        return isPrivateRoute ? getPrivateRoute(route) : getPublicRoute(route);
      })}
    </Switch>
  );
};

export default createRoutes;
