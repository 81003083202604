export enum UserPermission {
  COMPOSITE_PURCHASE_DOC = 'EnableCompositePurchase',
  TRANSFER_API_DOC = 'TransferApiDoc',
  RECALL_API_DOC = 'RecallApiDoc',
  PRICING_API_DOC = 'PricingApiDoc',
  EXTERNAL_PURCHASE_DOC = 'EnableExternalPurchase',
  RESALE_DOC = 'EnableResaleApiCalls',
  FULFILLMENT_DOC = 'EnableFulfillmentApiAccess',
  PUSH_INVENTORY_DOC = 'EnablePushInventoryApiAccess',
  AC2_SERVICE_DOC = 'Ac2ServiceAccess',
}
