import * as React from 'react';
import { groupBy, head, isEmpty } from 'lodash';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ClickParam } from 'antd/lib/menu';

import { IRoute } from './../../../constants';
import { authService } from './../../../services';

const { SubMenu } = Menu;

interface INavigationMenuProps {
  currentPage: string;
  handleClick: (param: ClickParam) => void;
}

export const NavigationMenu = (props: INavigationMenuProps) => {
  const userRoutes = authService.getCurrentUserRoutes();
  const routesForMenu = userRoutes.filter(route => route.menuSettings);

  const rootRoutes = routesForMenu.filter(route => !route.menuSettings?.section);
  const collapsedRoutes = routesForMenu.filter(route => route.menuSettings?.section);
  const groupedRoutes = groupBy(collapsedRoutes, (route: IRoute) => route.menuSettings?.section);

  const getMenuItem = (route: IRoute): JSX.Element => {
    return (
      <Menu.Item key={route.path}>
        <Link to={route.path}>{route.menuSettings?.label}</Link>
      </Menu.Item>
    );
  };

  const menuItems: JSX.Element[] = rootRoutes.map(route => getMenuItem(route));
  let subMenuItems: JSX.Element[] = [];

  for (const [section, routes] of Object.entries(groupedRoutes)) {
    if (routes.length === 1) {
      routes.forEach(route => menuItems.push(getMenuItem(route)));
      continue;
    }

    subMenuItems = routes.map(route => getMenuItem(route));
    menuItems.push(
      <SubMenu title={section} key={section}>
        {subMenuItems.map(item => item)}
      </SubMenu>
    );
  }

  const getNavigationMenu = (): JSX.Element => {
    if (head(menuItems) && isEmpty(subMenuItems)) {
      return <></>;
    }

    return (
      <Menu onClick={props.handleClick} selectedKeys={[props.currentPage]} mode="horizontal">
        {menuItems.map(item => item)}
      </Menu>
    );
  };

  return getNavigationMenu();
};

export default NavigationMenu;