import * as React from 'react';
import { DocLayout } from './../../layouts/DocLayout';
import { Layout } from 'antd';
import { ExternalPurchaseDoc } from './../../containers/ExternalPurchaseDoc';

export class ExternalPurchaseDocPage extends React.Component {  
  render() {
    return (
      <DocLayout>
        <Layout className="doc-root">
          <ExternalPurchaseDoc />
        </Layout>
      </DocLayout>
    );
  }
}
