import * as React from 'react';
import { Button as AntButton } from 'antd';

interface IButtonProps {
  children?: string;
  htmlType?: 'button' | 'reset' | 'submit';
  size?: 'small' | 'large' | 'middle' | 'default';
  type?: 'primary' | 'ghost' | 'dashed' | 'danger' | 'default';
  icon?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button = (props: IButtonProps) => {
  return (
    <AntButton
      type={props.type || 'default'}
      className={`button button__${props.size || 'default'}`}
      htmlType={props.htmlType || 'button'}
      onClick={props.onClick}
    >
      {props.children}
    </AntButton>
  );
};
