import { AnyAction } from 'redux';
import PartnerEndpointGroup from 'src/models/PartnerEndpointGroup';

export enum PartnerEndpointGroupsTypes {
  PARTNER_ENDPOINT_GROUPS_REQUEST = 'PARTNER_ENDPOINT_GROUPS_REQUEST',
  GET_PARTNER_ENDPOINT_GROUPS_SUCCESS = 'GET_PARTNER_ENDPOINT_GROUPS_SUCCESS',
  GET_PARTNER_ENDPOINT_GROUPS_ERROR = 'GET_PARTNER_ENDPOINT_GROUPS_ERROR',
  GET_PARTNER_ENDPOINT_GROUP_SUCCESS = 'GET_PARTNER_ENDPOINT_GROUP_SUCCESS',
  GET_PARTNER_ENDPOINT_GROUP_ERROR = 'GET_PARTNER_ENDPOINT_GROUP_ERROR',
  CREATE_PARTNER_ENDPOINT_GROUPS_SUCCESS = 'CREATE_PARTNER_ENDPOINT_GROUPS_SUCCESS',
  CREATE_PARTNER_ENDPOINT_GROUPS_ERROR = 'CREATE_PARTNER_ENDPOINT_GROUPS_ERROR',
  DELETE_PARTNER_ENDPOINT_GROUPS_SUCCESS = 'DELETE_PARTNER_ENDPOINT_GROUPS_SUCCESS',
  DELETE_PARTNER_ENDPOINT_GROUPS_ERROR = 'DELETE_PARTNER_ENDPOINT_GROUPS_ERROR',
  UPDATE_PARTNER_ENDPOINT_GROUPS_SUCCESS = 'UPDATE_PARTNER_ENDPOINT_GROUPS_SUCCESS',
  UPDATE_PARTNER_ENDPOINT_GROUPS_ERROR = 'UPDATE_PARTNER_ENDPOINT_GROUPS_ERROR',
  CREATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN = 'CREATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN',
  CREATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE = 'CREATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE',
  UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN = 'UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN',
  UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE = 'UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE',
  SELECT_PARTNER_ENDPOINT_GROUP = 'SELECT_PARTNER_ENDPOINT_GROUP'
}

export interface IPartnerEndpointGroupsState {
  isPartnerEndpointGroupsLoading: boolean;
  isCreateModalOpen: boolean;
  isUpdateModalOpen: boolean;
  partnerEndpointGroups: PartnerEndpointGroup[];
  selectedPartnerEndpointGroup?: PartnerEndpointGroup;
}

export interface IGetPartnerEndpointGroupsRequestAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.PARTNER_ENDPOINT_GROUPS_REQUEST;
}

export interface IGetPartnerEndpointGroupsSuccessAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUPS_SUCCESS;
  partnerEndpointGroups: PartnerEndpointGroup[];
}

export interface IGetPartnerEndpointGroupsErrorAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUPS_ERROR;
  messageText: string;
}

export interface IGetPartnerEndpointGroupSuccessAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUP_SUCCESS;
  partnerEndpointGroup: PartnerEndpointGroup;
}

export interface IGetPartnerEndpointGroupErrorAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.GET_PARTNER_ENDPOINT_GROUP_ERROR;
  messageText: string;
}

export interface ICreatePartnerEndpointGroupsSuccessAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUPS_SUCCESS;
  payload: PartnerEndpointGroup;
}

export interface ICreatePartnerEndpointGroupsErrorAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUPS_ERROR;
}

export interface IUpdatePartnerEndpointGroupsSuccessAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUPS_SUCCESS;
  payload: PartnerEndpointGroup;
}

export interface IUpdatePartnerEndpointGroupsErrorAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUPS_ERROR;
}

export interface IDeletePartnerEndpointGroupsSuccessAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.DELETE_PARTNER_ENDPOINT_GROUPS_SUCCESS;
  payload: number;
}

export interface IDeletePartnerEndpointGroupsErrorAction extends AnyAction {
  type: PartnerEndpointGroupsTypes.DELETE_PARTNER_ENDPOINT_GROUPS_ERROR;
}

export interface ICreatePartnerEndpointGroupModalOpen extends AnyAction {
  type: PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN;
}

export interface ICreatePartnerEndpointGroupModalClose extends AnyAction {
  type: PartnerEndpointGroupsTypes.CREATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE;
}

export interface IUpdatePartnerEndpointGroupModalOpen extends AnyAction {
  type: PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_OPEN;
}

export interface IUpdatePartnerEndpointGroupModalClose extends AnyAction {
  type: PartnerEndpointGroupsTypes.UPDATE_PARTNER_ENDPOINT_GROUP_MODAL_CLOSE;
}

export interface ISelectPartnerEndpointGroup extends AnyAction {
  type: PartnerEndpointGroupsTypes.SELECT_PARTNER_ENDPOINT_GROUP;
  payload: PartnerEndpointGroup;
}

export type IPartnerEndpointGroupsAction = IGetPartnerEndpointGroupsRequestAction
  | IGetPartnerEndpointGroupsSuccessAction
  | IGetPartnerEndpointGroupsErrorAction
  | ICreatePartnerEndpointGroupsSuccessAction
  | ICreatePartnerEndpointGroupsErrorAction
  | IDeletePartnerEndpointGroupsSuccessAction
  | IDeletePartnerEndpointGroupsErrorAction
  | IUpdatePartnerEndpointGroupsSuccessAction
  | IUpdatePartnerEndpointGroupsErrorAction
  | IGetPartnerEndpointGroupSuccessAction
  | IGetPartnerEndpointGroupErrorAction
  | ICreatePartnerEndpointGroupModalOpen
  | ICreatePartnerEndpointGroupModalClose
  | IUpdatePartnerEndpointGroupModalOpen
  | IUpdatePartnerEndpointGroupModalClose
  | ISelectPartnerEndpointGroup;
