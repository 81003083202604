import { message } from 'antd';
import { 
  PartnerMemberTypeAction,
  IPartnerMemberListAction,
  IPartnerMemberState } from '../types';
import { Member } from '../models';

const initialState: IPartnerMemberState = {
  partnerMemberList: [],
  selectedPartnerMember: undefined,
  isPartnerMemberAdding: false,
  isPartnerMembersLoading: false,
};

export const partnerMemberReducer = (
  state: IPartnerMemberState = initialState,
  action: IPartnerMemberListAction): IPartnerMemberState => {
    switch (action.type) {

      case PartnerMemberTypeAction.GET_PARTNER_MEMBER_REQUEST:
        return {
          ...state,
          isPartnerMembersLoading: true,
        };

      case PartnerMemberTypeAction.GET_PARTNER_MEMBER_SUCCESS:
        return {
          ...state,
          partnerMemberList: action.partnerMemberList,
          isPartnerMembersLoading: false,
        };

      case PartnerMemberTypeAction.GET_PARTNER_MEMBER_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          partnerMemberList: [],
          selectedPartnerMember: undefined,
          isPartnerMembersLoading: false,
        };

      case PartnerMemberTypeAction.SELECT_PARTNER_MEMBER:
        return {
          ...state,
          selectedPartnerMember: action.selectedPartnerMember,
        };

      case PartnerMemberTypeAction.ADD_PARTNER_MEMBER_REQUEST:
        return {
          ...state,
          isPartnerMemberAdding: true,
        };
        
      case PartnerMemberTypeAction.ADD_PARTNER_MEMBER_SUCCESS:
        state.partnerMemberList.push(action.addedPartnerMember);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerMemberAdding: false,
          selectedPartnerMember: action.addedPartnerMember,
        };

      case PartnerMemberTypeAction.ADD_PARTNER_MEMBER_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerMemberAdding: false,
        };

      case PartnerMemberTypeAction.REMOVE_PARTNER_MEMBER_REQUEST:
        return {
          ...state,
          isPartnerMembersLoading: true,
        };

      case PartnerMemberTypeAction.REMOVE_PARTNER_MEMBER_SUCCESS:
        const indexRemovedMember = state.partnerMemberList
          .findIndex((member: Member) => member.id === action.removedPartnerMemberId);
        state.partnerMemberList.splice(indexRemovedMember, 1);
        message.success(action.messageText, 4);
        return {
          ...state,
          isPartnerMembersLoading: false,
        };

      case PartnerMemberTypeAction.REMOVE_PARTNER_MEMBER_ERROR:
        message.error(action.messageText, 4);
        return {
          ...state,
          isPartnerMembersLoading: false,
        };

      default: return state;
    }
  };
