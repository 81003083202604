import * as React from 'react';
import { Layout } from 'antd';

import './../styles/index.less';
import { AlertContainer } from 'src/containers/Shared';
import { Header } from '../components/Common';

export class DocLayout extends React.Component {
  
  render() {
    return (
      <Layout>
        <AlertContainer />
        <Header />
        {this.props.children}
      </Layout>
    );
  }
}
